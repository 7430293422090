import React, { useState, useEffect } from "react";
import { IconButton, Badge, Modal, Box, Typography, Select, MenuItem, Button } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import axios from "axios";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
const SpecialisationSelector = ({ selectedStudent }) => {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState("");
    const [canchange, setCanchange] = useState(false);
    const [specialite, setSpecialite] = useState([]);

    const options = [
        { id: 1, label: "BACK-END", competences: JSON.stringify([
            { titre: "Programmation Serveur", niveau: 0 },
            { titre: "API RESTful", niveau: 0 },
            { titre: "Gestion des Bases de Données", niveau: 0 },
            { titre: "Architecture Serveur", niveau: 0 },
            { titre: "Sécurité Applicative", niveau: 0 }
        ])},
        { id: 1, label: "DESIGNER WEB", competences: JSON.stringify([
            { titre: "Maîtrise des Outils de Design", niveau: 0 },
            { titre: "Conception UI/UX", niveau: 0 },
            { titre: "Principes du Design Graphique", niveau: 0 },
            { titre: "Responsive Design", niveau: 0 },
            { titre: "Cohérence Visuelle", niveau: 0 },
            { titre: "Intégration des Technologies Modernes", niveau: 0 },
        ])},
        { id: 1, label: "INTÉGRATEUR", competences: JSON.stringify([
            { titre: "Langages Front-End", niveau: 0 },
            { titre: "Interfaces Performantes", niveau: 0 },
            { titre: "Frameworks Front-Ends", niveau: 0 },
            { titre: "Connexion Front-End/Back-End", niveau: 0 }
        ])},
        { id: 2, label: "CHEF DE PROJET DIGITAL", competences: JSON.stringify([
            { titre: "Management de projet", niveau: 0 },
            { titre: "⁠Stratégie et opérations", niveau: 0 },
            { titre: "⁠Leadership", niveau: 0 },
            { titre: "⁠Planification", niveau: 0 },
            { titre: "Collaboration", niveau: 0 },
            { titre: "⁠Gestion du Changement", niveau: 0 },
            { titre: "⁠Communication Business", niveau: 0 },
            { titre: "⁠Gestion des risques", niveau: 0 },
            { titre: "⁠Gestion du budget", niveau: 0 },
            { titre: "⁠Nettoyage des données", niveau: 0 },
            { titre: "⁠Réflexion critique", niveau: 0 },
            { titre: "⁠Éthique des données", niveau: 0 },
            { titre: "⁠Visualisation des données", niveau: 0 },
            { titre: "⁠Business intelligence", niveau: 0 }
        ])},
        { id: 2, label: "PRODUCT OWNER", competences: JSON.stringify([
            { titre: "Management de projet", niveau: 0 },
            { titre: "⁠Stratégie et opérations", niveau: 0 },
            { titre: "⁠Leadership", niveau: 0 },
            { titre: "⁠Planification", niveau: 0 },
            { titre: "Collaboration", niveau: 0 },
            { titre: "⁠Gestion du Changement", niveau: 0 },
            { titre: "⁠Communication Business", niveau: 0 },
            { titre: "⁠Gestion des risques", niveau: 0 },
            { titre: "⁠Gestion du budget", niveau: 0 },
            { titre: "⁠Nettoyage des données", niveau: 0 },
            { titre: "⁠Réflexion critique", niveau: 0 },
            { titre: "⁠Éthique des données", niveau: 0 },
            { titre: "⁠Visualisation des données", niveau: 0 },
            { titre: "⁠Business intelligence", niveau: 0 }
        ])},
        { id: 2, label: "ACCOUNT MANAGER", competences: JSON.stringify([
            { titre: "Gestion de Portefeuille Clients", niveau: 0 },
            { titre: "Négociation Commerciale", niveau: 0 },
            { titre: "Suivi des Performances", niveau: 0 },
            { titre: "Fidélisation Client", niveau: 0 }
        ])},
        { id: 2, label: "CHARGÉ DE COM. DIGITALE", competences: JSON.stringify([
            { titre: "Marketing de contenu", niveau: 0 },
            { titre: "Email marketing", niveau: 0 },
            { titre: "⁠Marketing d'influence", niveau: 0 },
            { titre: "Gestion de projet", niveau: 0 },
            { titre: "Relation publique", niveau: 0 },
            { titre: "⁠Stratégie de contenus", niveau: 0 },
            { titre: "⁠Géstion des réseaux sociaux", niveau: 0 },
            { titre: "⁠SEO & Analytics", niveau: 0 },
            { titre: "⁠Publicite digitale (SEA, Social ads)", niveau: 0 }
        ])},
        { id: 3, label: "Community Manager", competences: JSON.stringify([
            { titre: "Culture et Compétences Numériques Essentielles", niveau: 0 },
            { titre: "Stratégie de contenu", niveau: 0 },
            { titre: "Implémentation de la stratégie", niveau: 0 },
            { titre: "Optimisation et analyse de la stratégie", niveau: 0 },
            { titre: "Maîtrise de l’Intelligence Artificielle pour le Digital", niveau: 0 },
            { titre: "Gestion de Projet Digital et Méthodes Agiles", niveau: 0 },
            { titre: "Stratégie et Performance en Marketing Digital", niveau: 0 },
            { titre: "Gestion et Animation de Communauté & Rédaction Web", niveau: 0 },
            { titre: "Growth Hacking et Optimisation de la Croissance", niveau: 0 },
            { titre: "Création et Optimisation d'une Boutique E-Commerce", niveau: 0 }
        ])},
        { id: 3, label: "Social Media Manager", competences: JSON.stringify([
            { titre: "Culture et Compétences Numériques Essentielles", niveau: 0 },
            { titre: "Stratégie et Création de Contenu pour les Réseaux Sociaux", niveau: 0 },
            { titre: "Maîtrise de l’Intelligence Artificielle pour le Digital", niveau: 0 },
            { titre: "Gestion de Projet Digital et Méthodes Agiles", niveau: 0 },
            { titre: "Stratégie et Performance en Marketing Digital", niveau: 0 },
            { titre: "Gestion et Animation de Communauté & Rédaction Web", niveau: 0 },
            { titre: "Growth Hacking et Optimisation de la Croissance", niveau: 0 },
            { titre: "Création et Optimisation d'une Boutique E-Commerce", niveau: 0 }
        ])},
        { id: 3, label: "Specialist Digital Marketing", competences: JSON.stringify([
            { titre: "Culture et Compétences Numériques Essentielles", niveau: 0 },
            { titre: "Stratégie et Création de Contenu pour les Réseaux Sociaux", niveau: 0 },
            { titre: "Maîtrise de l’Intelligence Artificielle pour le Digital", niveau: 0 },
            { titre: "Gestion de Projet Digital et Méthodes Agiles", niveau: 0 },
            { titre: "Stratégie et Performance en Marketing Digital", niveau: 0 },
            { titre: "Gestion et Animation de Communauté & Rédaction Web", niveau: 0 },
            { titre: "Growth Hacking et Optimisation de la Croissance", niveau: 0 },
            { titre: "Création et Optimisation d'une Boutique E-Commerce", niveau: 0 }
        ])},
        { id: 3, label: "Content Creator (Video & Visual)", competences: JSON.stringify([
            { titre: "Culture et Compétences Numériques Essentielles", niveau: 0 },
            { titre: "Stratégie et Création de Contenu pour les Réseaux Sociaux", niveau: 0 },
            { titre: "Maîtrise de l’Intelligence Artificielle pour le Digital", niveau: 0 },
            { titre: "Gestion de Projet Digital et Méthodes Agiles", niveau: 0 },
            { titre: "Stratégie et Performance en Marketing Digital", niveau: 0 },
            { titre: "Gestion et Animation de Communauté & Rédaction Web", niveau: 0 },
            { titre: "Growth Hacking et Optimisation de la Croissance", niveau: 0 },
            { titre: "Création et Optimisation d'une Boutique E-Commerce", niveau: 0 }
        ])},
        { id: 3, label: "Growth Hacker", competences: JSON.stringify([
            { titre: "Culture et Compétences Numériques Essentielles", niveau: 0 },
            { titre: "Stratégie et Création de Contenu pour les Réseaux Sociaux", niveau: 0 },
            { titre: "Maîtrise de l’Intelligence Artificielle pour le Digital", niveau: 0 },
            { titre: "Gestion de Projet Digital et Méthodes Agiles", niveau: 0 },
            { titre: "Stratégie et Performance en Marketing Digital", niveau: 0 },
            { titre: "Gestion et Animation de Communauté & Rédaction Web", niveau: 0 },
            { titre: "Growth Hacking et Optimisation de la Croissance", niveau: 0 },
            { titre: "Création et Optimisation d'une Boutique E-Commerce", niveau: 0 }
        ])},
        { id: 3, label: "E-Commerce Manager", competences: JSON.stringify([
            { titre: "Culture et Compétences Numériques Essentielles", niveau: 0 },
            { titre: "Stratégie et Création de Contenu pour les Réseaux Sociaux", niveau: 0 },
            { titre: "Maîtrise de l’Intelligence Artificielle pour le Digital", niveau: 0 },
            { titre: "Gestion de Projet Digital et Méthodes Agiles", niveau: 0 },
            { titre: "Stratégie et Performance en Marketing Digital", niveau: 0 },
            { titre: "Gestion et Animation de Communauté & Rédaction Web", niveau: 0 },
            { titre: "Growth Hacking et Optimisation de la Croissance", niveau: 0 },
            { titre: "Création et Optimisation d'une Boutique E-Commerce", niveau: 0 }
        ])},
        { id: 3, label: "Consultant en digitalisation", competences: JSON.stringify([
            { titre: "Culture et Compétences Numériques Essentielles", niveau: 0 },
            { titre: "Stratégie et Création de Contenu pour les Réseaux Sociaux", niveau: 0 },
            { titre: "Maîtrise de l’Intelligence Artificielle pour le Digital", niveau: 0 },
            { titre: "Gestion de Projet Digital et Méthodes Agiles", niveau: 0 },
            { titre: "Stratégie et Performance en Marketing Digital", niveau: 0 },
            { titre: "Gestion et Animation de Communauté & Rédaction Web", niveau: 0 },
            { titre: "Growth Hacking et Optimisation de la Croissance", niveau: 0 },
            { titre: "Création et Optimisation d'une Boutique E-Commerce", niveau: 0 }
        ])},
        { id: 3, label: "Chef de projet", competences: JSON.stringify([
            { titre: "Culture et Compétences Numériques Essentielles", niveau: 0 },
            { titre: "Stratégie et Création de Contenu pour les Réseaux Sociaux", niveau: 0 },
            { titre: "Maîtrise de l’Intelligence Artificielle pour le Digital", niveau: 0 },
            { titre: "Gestion de Projet Digital et Méthodes Agiles", niveau: 0 },
            { titre: "Stratégie et Performance en Marketing Digital", niveau: 0 },
            { titre: "Gestion et Animation de Communauté & Rédaction Web", niveau: 0 },
            { titre: "Growth Hacking et Optimisation de la Croissance", niveau: 0 },
            { titre: "Création et Optimisation d'une Boutique E-Commerce", niveau: 0 }
        ])}
    ];

    useEffect(() => {
        console.log(selectedStudent);
        if (selectedStudent.canchangespec === 0) {
            setCanchange(false)
        }
        if (selectedStudent.canchangespec === 1) {
            setCanchange(true)
        }
        
        if (!selectedStudent) return;

        const idMapping = {
            "Développeur web": 1,
            "Référent digital": 2,
            "Digital creator": 3
        };

        const referentielId = idMapping[selectedStudent.referentiel];

        if (referentielId) {
            setSpecialite(options.filter(item => item.id === referentielId));
        }
    }, [selectedStudent]);

    const handleUpdateStudent = async () => {
        const selectedOption = options.find(option => option.label === selected);
        if (!selectedOption) {
            alert("Veuillez sélectionner une spécialisation.");
            return;
        }

        const data = {
            specialisation: selectedOption.label,
            competences: selectedOption.competences
        };

        try {
            await axios.put(`https://backend-codeschool241.onrender.com/referentiel/etudiant/${selectedStudent.id}`, data);
            alert("Spécialisation mise à jour avec succès !");
            setOpen(false);  // Fermer le popup après validation
            window.location.reload();
        } catch (error) {
            console.error("Erreur lors de la mise à jour :", error);
            alert("Une erreur s'est produite.");
        }
    };

    return (
<Container maxWidth="100%" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h2 className="boldtitle" style={{ textAlign: 'center' }}>Choix de spécialisation</h2>

        <Box
          sx={{
            display: 'flex',
            // flexDirection: { xs: 'column', md: 'row' },
            flexDirection: 'column',
            gap: 2,
            width: '100%',
          }}
        >

                    <Select
                        fullWidth
                        value={selected}
                        disabled={!canchange}
                        onChange={(e) => setSelected(e.target.value)}
                        displayEmpty
                    >
                        <MenuItem value="" disabled>Sélectionner une spécialisation</MenuItem>
                        {specialite.map((item) => (
                            <MenuItem key={item.label} value={item.label}>
                                {item.label}
                            </MenuItem>
                        ))}
                    </Select>

                    <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
                        <Button           
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleUpdateStudent} disabled={!selected}
                        >
                            Valider
                        </Button>
                    </Box>
                    </Box>


</Paper>
</Container>
    );
};

export default SpecialisationSelector;
