import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import '../../App.css';
import ListApprenant from './ListApprenant';
import AddStudent from './AddStudent'; // Nouveau composant
import Etudiant from './Etudiant';
import GlobalGauge from '../StudentDashboard/GlobalGauge/GlobalGauge';
import BaddestGauge from '../StudentDashboard/BaddestGauge/BaddestGauge';
import ResponseSlide from './ResponseSlide';
import { Typography } from '@mui/material';
import CurrentTopStudents from '../StudentDashboard/CurrentTopStudents/CurrentTopStudents';




function AdminFirstModule({ classe, filteredUsers }) {
  const [open, setOpen] = useState(false);
  const [topstudents, setTopstudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState({});
  const [view, setView] = useState(true); // true = "ListApprenant", false = "AddStudent"

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleView = () => {
    setView((prevView) => !prevView); // Bascule entre true et false
  };

React.useEffect(() => {
  if (classe && classe.length > 0) {
    classe.forEach(etudiant => {
      if (typeof etudiant.competences === 'string' && typeof etudiant.briefs === 'string') {
        etudiant.competences = JSON.parse(etudiant.competences);
        etudiant.briefs = JSON.parse(etudiant.briefs);
      }
    });


    
    const sortedArray = classe.slice().sort((a, b) => {
      const totalNiveauA = a.competences.reduce((acc, competence) => acc + competence.niveau, 0);
      const totalNiveauB = b.competences.reduce((acc, competence) => acc + competence.niveau, 0);
      return totalNiveauB - totalNiveauA;
    });

    setTopstudents(sortedArray);
  } else {
    console.error("Les données sont vides ou incorrectes.");
  }
}, []);
const [isValidatingBox, setIsValidatingBox] = React.useState(true);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3} className="row1">

        {/* Affichage conditionnel */}
        {view ? (
          <ListApprenant
            classe={classe}
            open={open}
            setOpen={setOpen}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            setSelectedStudent={setSelectedStudent}
            toggleView={toggleView}
            view={view}
            filteredUsers={filteredUsers}
          />
        ) : (
          <Grid 
  item 
  xs={12} sm={10} md={9} lg={8} 
  sx={{ pt: { xs: '16px', sm: '20px', md: '24px' }, pl: { xs: '8px', sm: '16px', md: '24px' } }}
>
  <Paper
    sx={{
      p: 2,
      display: 'flex',
      flexDirection: 'column',
      height: { xs: 'auto', md: 520 },
      minHeight: 350,
    }}
  >
    <AddStudent 
      classe={classe}
      toggleView={toggleView}
      view={view}
      filteredUsers={filteredUsers}
    />
  </Paper>
</Grid>

        )}

        {/* Composant Etudiant */}
        <Etudiant
          classe={classe}
          open={open}
          setOpen={setOpen}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          selectedStudent={selectedStudent}
          filteredUsers={filteredUsers}
          isValidatingBox ={isValidatingBox}
          setIsValidatingBox={setIsValidatingBox}
        />

        {/* Gauges */}
        <Grid
          container
          spacing={3}
          lg={4}
          sx={{
            p: 3,
            pb: 0,
            paddingTop: { xs: '46px', md: '62px' },
            display: 'flex',
            width: '-webkit-fill-available',
            height: '500px',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingBottom:'13px',
            paddingRight:0,
            paddingLeft: { xs: '48px', md: '15px' },
          }}
        >
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '48%',
              justifyContent: 'space-between',
            }}
          >
            <GlobalGauge classe={classe} />
          </Paper>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '48%',
              justifyContent: 'space-between',
            }}
          >
            <BaddestGauge classe={classe} />
          </Paper>
        </Grid>
      </Grid>
      <Grid
          container
          // spacing={3}
          lg={12}
          sx={{
            p: 0,
            pb: 0,
            display: 'flex',
            width: '100%',
            paddingRight:0,

          }}
        >
      <Grid  xs={12} md={8} lg={6} sx={{
        marginBottom: { xs: '10px', md: '0px' },
        }}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          width: { xs: '100%', md: '97%' },
          justifyContent: 'space-between',


        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          Réponses aux briefs
        </Typography>
        <ResponseSlide filteredUsers={filteredUsers} classe={classe}/>
      </Paper>
      </Grid>
      <Grid  xs={12} md={8} lg={6}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height:'100%', overflow: 'hidden' }}>
              <CurrentTopStudents topstudents={topstudents} classe={classe} filteredUsers={filteredUsers} />
            </Paper>
          </Grid>
      </Grid>

    </Container>
  );
}

export default AdminFirstModule;
