import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React from "react";
import Divider from "@mui/material/Divider";
import "../../App.css";
import Skillslevels from "../StudentDashboard/Skillslevels";

function PersonalProgress({ selectedStudent }) {
  const competences = selectedStudent.competences || [];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12} sx={{ width: "100%" }}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            maxWidth: "100%",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              textAlign: "center",
              color: "#ED462F",
            }}
          >
            Niveaux de compétences
          </span>
          <br />
          <br />
          <div style={{ width: "100%" }} className="head-table-skills">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "20px",
                alignItems: "center",
              }}
            >
              <h3 style={{ margin: 0 }}>Compétences</h3>
              <h3 style={{ margin: 0 }}>Niveaux</h3>
            </div>
            <Divider />
          </div>

          {/* Conteneur scrollable */}
          <div
            style={{
              width: "100%",
              maxHeight: "400px", // Hauteur max pour le scroll
              overflowY: "auto",
              paddingRight: "10px", // Ajout d'un espace pour le scrollbar
            }}
          >
            {competences.length > 0 ? (
              competences.map((label) => (
                <div style={{ width: "100%" }} key={label.titre}>
                  <div className="main-progress-box">
                    <span>{label.titre}</span>
                    <Skillslevels niveau={label.niveau} />
                  </div>
                  <Divider />
                </div>
              ))
            ) : (
              <p style={{ textAlign: "center", color: "gray" }}>
                Aucune compétence disponible.
              </p>
            )}
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default PersonalProgress;
