import React, { useState, useEffect } from "react";
import { FormControlLabel, Switch, Box } from "@mui/material";
import axios from "axios";

const ChangeSpecialisationSwitch = ({ student }) => {
  const [canChange, setCanChange] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false); // 🔥 Bloque l'écrasement temporaire

  useEffect(() => {
    const fetchPermission = async () => {
      try {
        if (!isUpdating) { // ⚠️ Bloquer la mise à jour si handleToggle vient d'être exécuté
          const response = await axios.get(
            `https://backend-codeschool241.onrender.com/canchange/etudiant/${student.id}`
          );
          setCanChange(response.data.canchange === 1);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du statut:", error);
      }
    };

    fetchPermission();
  }, [student.id, isUpdating]); // ⚠️ Ajouter `isUpdating` pour relancer l'effet après le délai

  const handleToggle = async () => {

    let newStatus = canChange ? 0 : 1;

    console.log(newStatus);
    

    setIsUpdating(true); // 🔥 Empêche fetchPermission d'écraser l'état
    setCanChange(newStatus === 1);

    try {
      await axios.put(
        `https://backend-codeschool241.onrender.com/canchange/etudiant/${student.id}`,
        { canchange: newStatus }
      );
    } catch (error) {
      console.error("Erreur lors de la mise à jour:", error);
    }

  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
      <span>Peut changer de spécialisation</span>
      <Switch checked={canChange} onChange={handleToggle} color="primary" />
    </Box>
  );
};

export default ChangeSpecialisationSwitch;
