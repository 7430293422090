import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from "firebase/app";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
const firebaseConfig = {
  apiKey: "AIzaSyBFsTdqPSNL268ri7Xe0X5FxpS7nOIg1qc",
  authDomain: "auth-2a5c6.firebaseapp.com",
  projectId: "auth-2a5c6",
  storageBucket: "auth-2a5c6.appspot.com",
  messagingSenderId: "824837136835",
  appId: "1:824837136835:web:8a505fedeb1444935a5d76",
  measurementId: "G-522X2NX0E1"
};

initializeApp(firebaseConfig);

const AddStudent = ({ onApprenantAdded, filteredUsers, toggleView, view }) => {
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("077000000");
  const [discipline, setDiscipline] = useState(10);
  const [referentiel, setReferentiel] = useState("");
  const [specialisation, setSpecialisation] = useState(" ");
  const [promo, setPromo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  // Vérifier si filteredUsers est disponible et non vide
  useEffect(() => {
    if (filteredUsers && filteredUsers.length > 0) {
      setReferentiel(filteredUsers[0].referentiel);
      setPromo(filteredUsers[0].promo);
    }
    console.log(filteredUsers);
    
  }, [filteredUsers]); // Dépendance sur filteredUsers

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");
  
    // Créer une nouvelle instance d'application Firebase pour éviter de perturber l'utilisateur connecté
    const secondaryApp = initializeApp(firebaseConfig, "SecondaryApp"); // Instance nommée
    const tempAuth = getAuth(secondaryApp);
  
    try {
      // Créer le nouvel utilisateur avec l'instance temporaire
      const userCredential = await createUserWithEmailAndPassword(
        tempAuth,
        email,
        "12345678" // Mot de passe par défaut
      );
      const firebaseUserId = userCredential.user.uid;
  
      // Ajouter l'utilisateur à la base de données SQL
      const newApprenant = {
        id: firebaseUserId,
        nom,
        prenom,
        email,
        phone,
        referentiel,
        discipline,
        specialisation,
        promo,
        competences: [{ titre: "Découverte", niveau: 0 }],
        briefs: [],
      };
  
      await axios.post("https://backend-codeschool241.onrender.com/etudiants", newApprenant);

      const currentTimeGMT1Libreville = new Date();
      currentTimeGMT1Libreville.setHours(currentTimeGMT1Libreville.getHours() + 1);
      await axios.post('https://backend-codeschool241.onrender.com/activites', {
        id_user:filteredUsers[0].id, 
        role:'formateur', 
        action:`a crée le compte de l\'apprennant ${newApprenant.prenom} ${newApprenant.nom}`,
        date: currentTimeGMT1Libreville
      })
  
      if (onApprenantAdded) onApprenantAdded(newApprenant);
  
      // Réinitialiser le formulaire
      setNom("");
      setPrenom("");
      setEmail("");
      setPhone("");
      setSpecialisation("");
    } catch (err) {
      console.error("Erreur lors de l'ajout de l'apprenant :", err);
      setError("Une erreur est survenue. Veuillez réessayer.");
      
    } finally {
      setIsLoading(false);
        window.location.reload()
    }
  };
  

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%", margin: "0 auto" }}>
      <div style={{display:'flex', justifyContent:'space-between'}}>
        <h2 className="boldtitle">Ajouter un apprenant</h2>
        <IconButton
            variant="contained"
            color="primary"
            onClick={toggleView}
        >
            {view ? <PersonAddAlt1Icon/> : <CloseIcon/>}
        </IconButton>
      </div>
      {error && <Alert severity="error" style={{ marginBottom: "20px" }}>{error}</Alert>}
      <TextField
        label="Nom"
        value={nom}
        onChange={(e) => setNom(e.target.value)}
        fullWidth
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Prénom"
        value={prenom}
        onChange={(e) => setPrenom(e.target.value)}
        fullWidth
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}
      />
      {/* <TextField
        label="Téléphonee"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        fullWidth
        margin="normal"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PhoneIcon />
            </InputAdornment>
          ),
        }}
      /> */}
      {/* <TextField
        label="Spécialisation"
        value={specialisation}
        onChange={(e) => setSpecialisation(e.target.value)}
        fullWidth
        margin="normal"
      /> */}
      <br /><br />

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={isLoading}
        startIcon={isLoading ? <CircularProgress size={20} /> : null}
      >
        {isLoading ? "En cours..." : "Ajouter"}
      </Button>
    </form>
  );
};

export default AddStudent;
