import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import AccordionTracker from './AccordionTracker';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';


function Tracker({classe, filteredUsers, skills, briefs, setBriefs}) {
    return ( 
        <div>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4, pr:0 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={12}>
                        <div style={{width:'100%', padding:' 20px 0', display:'flex', justifyContent:'center'}}>
                        </div>
                        <Paper
                            sx={{
                            pt: 2,
                            pb: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'fit-content',
                            width:'100%'
                            }}
                        >
                            <span style={{fontSize:'20px', fontWeight:'bold', textAlign:'center', marginBottom:'15px', color:'#ED462F'}}>
                                Tracker
                            </span>

                            <AccordionTracker
                            filteredUsers={filteredUsers}
                            skills={skills}
                            classe={classe}
                            activities={briefs}
                            setActivities={setBriefs}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
     );
}

export default Tracker;