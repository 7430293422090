import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import '../../App.css';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import { Typography } from "@mui/material";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const AvatarContainer = styled('div')({
  position: 'relative',
  display: 'inline-block',
});

const IconOverlay = styled(IconButton)({
  position: 'absolute',
  bottom: 0,
  right: '15px',
  backgroundColor: '#ED462F',
  borderRadius: '50%',
  padding: 4,
  zIndex: 1
});

function ChangeInformations({ filteredUsers }) {
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [user, setUser] = useState({});
  const [image, setImage] = useState('');
  const [photo, setphoto] = useState(filteredUsers[0]?.photo || '');
  const [fichier, setFichier] = useState(null);
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    photo: '/',
    phone: '',
    linkedin: '',
    github: '',
    facebook: '',
    site: '',
    age: '',
  });
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    console.log(filteredUsers[0]);
    
    if (filteredUsers && filteredUsers.length > 0) {
      const userData = filteredUsers[0];
      setUser(userData);
      setImage(userData.photo);
      setphoto(userData.photo);
      setFormData({
        nom: userData.nom,
        prenom: userData.prenom,
        photo: userData.photo,
        phone: userData.phone,
        linkedin: userData.linkedin,
        github: userData.github,
        facebook: userData.facebook,
        site: userData.site,
        age: userData.age,
      });
    }
  }, [filteredUsers]);

  const handleImageChange = (event) => {
    const item = event.target.files[0];
    handleUpload(item);

    const imageUrl = URL.createObjectURL(event.target.files[0]);
    setImage(imageUrl);
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === "phone" && !/^\d{0,9}$/.test(value)) return;
    if (["linkedin", "github", "facebook", "site"].includes(name) && !/^https?:\/\//.test(value) && value !== "") return;
    if (name === "age" && !/^\d*$/.test(value)) return;
  
    setFormData({ 
      ...formData, 
      [name]: name === "age" ? (value ? parseInt(value, 10) : '') : value 
    });
  };
  

  const handleUpload = (item) => {
    if (!item) return;

    const storageRef = ref(storage, `/files/${item.name}`);
    const uploadTask = uploadBytesResumable(storageRef, item);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setphoto(downloadURL);
        });
      }
    );
  };

  const send = async () => {
    console.log("Données envoyées:", formData);
    console.log("User ID:", user.id);

    // if (!formData.nom || !formData.prenom || !formData.photo || 
    //     !formData.phone || !formData.linkedin || !formData.github || 
    //     !formData.facebook || !formData.site || formData.age === undefined) {
    //   setError("Veuillez remplir tous les champs.");
    //   return;
    // }

    setLoading(true);
    try {
      await axios.put(`https://backend-codeschool241.onrender.com/etudiant/${user.id}`, {
        ...formData,
        photo: photo,
      });
      setSuccess("Informations à jour avec succès!");
      window.location.reload();
    } catch (error) {
      console.error("Erreur lors de la mise à jour des données:", error);
      setError("Erreur lors de la mise à jour des données!");
    } finally {
      setLoading(false);
    }
};


  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };





  return (
    <div>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={12}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 'fit-content',
                justifyContent: 'space-between',
              }}
            >
              <h2 className='boldtitle' style={{ textAlign: 'center' }}>Changer ses informations</h2>
              {error && <Alert severity="error">{error}</Alert>}
              {success && <Alert severity="success">{success}</Alert>}
              <div style={{ display: 'flex', margin: 'auto' }}>
                <AvatarContainer>
                  <Avatar
                    alt={`${formData.prenom} ${formData.nom}`}
                    src={image}
                    sx={{ width: 100, height: 100, marginRight: 2, cursor: 'pointer' }}
                    onClick={() => document.getElementById('file-input').click()}
                  />
                  <IconOverlay
                    onClick={() => document.getElementById('file-input').click()}
                  >
                    <AddIcon />
                  </IconOverlay>
                </AvatarContainer>
                <VisuallyHiddenInput
                  id="file-input"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
              <br />
 <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField label="Prénom" variant="outlined" name="prenom" value={formData.prenom} onChange={handleChange} fullWidth />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField label="Nom" variant="outlined" name="nom" value={formData.nom} onChange={handleChange} fullWidth />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField label="Âge" variant="outlined" name="age" value={formData.age} onChange={handleChange} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary">
          Veuillez entrer un numero de telephone valide au format 0XX XX XX XX pour le champ suivant :
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField label="Téléphone" variant="outlined" name="phone" value={formData.phone} onChange={handleChange} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary">
          Veuillez coller un lien valide commençant par https:// pour les champs suivants :
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField label="LinkedIn" variant="outlined" name="linkedin" value={formData.linkedin} onChange={handleChange} fullWidth />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField label="GitHub" variant="outlined" name="github" value={formData.github} onChange={handleChange} fullWidth />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField label="Facebook" variant="outlined" name="facebook" value={formData.facebook} onChange={handleChange} fullWidth />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField label="Site web" variant="outlined" name="site" value={formData.site} onChange={handleChange} fullWidth />
      </Grid>

      
      <Grid item xs={12}>
        <Button variant="contained" fullWidth onClick={send} disabled={loading}>
          {loading ? "Envoi en cours..." : "Enregistrer"}
        </Button>
      </Grid>
    </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
      >
        <Alert onClose={handleCloseNotification} severity={notification.severity}>
          {notification.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ChangeInformations;
