import React, { useState } from 'react';import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ProgressBar from './ProgressBar';
import Divider from '@mui/material/Divider';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Gauge } from '@mui/x-charts/Gauge';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import { Stepper, Step, StepLabel, StepContent } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Grid} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import {
  List,
  ListItem,
  ListItemButton,
} from '@mui/material';
import DeleteBrief from './DeleteBrief';

import {useMediaQuery } from "@mui/material";

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { CircularProgress, Snackbar, Alert } from "@mui/material";
import AddActivity from './AddActivity';
import Avatar from '@mui/material/Avatar';




import { io } from 'socket.io-client';

const socket = io('https://backend-codeschool241.onrender.com', {
  transports: ['websocket', 'polling'],
});// const socket = io('http://localhost:4001');
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function AccordionTracker({ classe, filteredUsers, skills, activities, setActivities}) {

  
  const [activeStep, setActiveStep] = React.useState(0);
  const [expanded, setExpanded] = React.useState('panel1');
  const [array, setarray] = React.useState([]);
  const [objectifs, setobjectifs] = React.useState([]);
  const [briefs, setbriefs] = React.useState([]);
  const [newModule, setNewModule] = React.useState({

    module: '',
    descriprion: '',
    debut: '',
    fin: '',
    competences: '',
    cohorte: filteredUsers[0].referentiel,
    promo: filteredUsers[0].promo

  });

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleClick = (module) => {
    console.log(module.id_module); // Affiche l'élément cliqué dans la console
  };

  const [idObjectifAdded, setIdObjectifAdded] = React.useState(0)

  React.useEffect(() => {
    const referentiel = filteredUsers[0].referentiel;
    const promo = filteredUsers[0].promo;
    const checkAuth = async () => {
      const response = await axios.get('https://backend-codeschool241.onrender.com/modules');
      // setarray(response.data);
      const response3 = await axios.get(`https://backend-codeschool241.onrender.com/api/allbriefs`, {
        params: { promo, referentiel }
      });
      setbriefs(response3.data);
      console.log(promo);
      console.log(referentiel);

      

      socket.emit('getObjectifs');
      socket.on('objectifsList', (data) => {
        setobjectifs(data);
        // console.log(data);
      });
  
      socket.on('objectifAdded', (message) => {
        setIdObjectifAdded(message.id);
      });

      socket.emit('getmodules');
      socket.on('modulesList', (data) => {

        setarray(data);
        console.log(data);
      });
  
      
      socket.on('moduleAdded', (message) => {
        console.log(message);
      });
  
      socket.on('error', (error) => {
        alert(error.message);
      });
  
      return () => {
        socket.disconnect();
      };
    };
    checkAuth();
  }, []);
  const modulesByRef = array.filter(module => module.cohorte === filteredUsers[0].referentiel);
  const modules = modulesByRef.filter(module => module.promo === filteredUsers[0].promo);

  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split("-");
    return new Date(year, month - 1, day);
  };
  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split('-');
    return `${day}-${month}-${year}`;
  };
  

  const handleInputChange = (field, value) => {
    setNewModule((prev) => ({ ...prev, [field]: value }));
  };
  
    const transformSkillsToTitles = (skills) => {
      return skills.map(skill => skill.titre);
  };

  const competencesList = transformSkillsToTitles(skills);


    
  
  const [notification, setNotification] = useState({ open: false, type: "", message: "" });
  const [newObjectif, setNewObjectif] = useState('');
  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);        
  const toggleCompetence = (competence) => {
    setNewModule((prev) => {
      const competences = prev.competences.includes(competence)
        ? prev.competences.filter((c) => c !== competence) // Si déjà sélectionnée, on la retire
        : [...prev.competences, competence]; // Sinon on l'ajoute
  
      return { ...prev, competences }; // Mettre à jour l'état avec les compétences sélectionnées
    });
  };
  const handleAddModule = async () => {
    const formattedModule = {
      ...newModule,
      debut: formatDate(newModule.debut),
      fin: formatDate(newModule.fin),
      competences: newModule.competences, // Convertir en tableau stringifié
    };
  
    try {
      // Poster le nouveau module à ton API
      socket.emit('addmodule', formattedModule);
      // setarray((prev) => [...prev, formattedModule]);
      // setNewModule({ module: '', descriprion: '', debut: '', fin: '', competences: '', cohorte: filteredUsers[0].referentiel }); // Réinitialiser l'état
      console.log(formattedModule);

      const currentTimeGMT1Libreville = new Date();
      currentTimeGMT1Libreville.setHours(currentTimeGMT1Libreville.getHours() + 1);
      await axios.post('https://backend-codeschool241.onrender.com/activites', {
        id_user:filteredUsers[0].id, 
        role:'formateur', 
        action:`a ajouté le module \"${newModule.module}\"`,
        date: currentTimeGMT1Libreville
      })
      setLoading(false); // Arrête le chargement
      setNotification({
        open: true,
        type: "success",
        message: "Module ajouté avec succès !",})

    } catch (error) {
      console.error('Erreur lors de l’ajout du module:', error);
      setNotification({
        open: true,
        type: "error",
        message: "Erreur lors de l'ajout de l'Activité.",
      });
    } finally{
      setNewModule({
        module: '',
        descriprion: '',
        debut: '',
        fin: '',
        competences: '',
        cohorte: filteredUsers[0].referentiel
      })
      setActiveStep(0)
      setExpanded('')
      setLoading(false); // Arrête le chargement
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Exemple d'attente
    }
  };

  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };



  const ajouterObjectif = async(module) => {
    try{
      if (newObjectif) {
        socket.emit('addObjectif', { objectif: newObjectif, id_module: module.id_module });
        const currentTimeGMT1Libreville = new Date();
        currentTimeGMT1Libreville.setHours(currentTimeGMT1Libreville.getHours() + 1);
        await axios.post('https://backend-codeschool241.onrender.com/activites', {
          id_user:filteredUsers[0].id, 
          role:'formateur', 
          action:`a ajouté l\'activité \"${newObjectif}\"`,
          date: currentTimeGMT1Libreville
        })

      // Si succès
      setNotification({
        open: true,
        type: "success",
        message: "Activité ajouté avec succès !",})
        

      } else {
        alert('Veuillez remplir tous les champs');
      }
    } catch{
      setNotification({
        open: true,
        type: "error",
        message: "Erreur lors de l'ajout de l'Activité.",
      });
        console.error('Erreur lors de l’ajout de l\'Activité');
    }finally{

      // setLoading(false); // Arrête le chargement
      setNewObjectif('');
      setShowForm(false);
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Exemple d'attente

    }
  };

  const isMobile = useMediaQuery("(max-width: 768px)"); // Détection mobile


  const [open, setOpen] = useState(false);
const [brief, setBrief] = useState(null);
const [number, setNumber] = useState([]);
  const [allResponses, setAllResponses] = useState([]);


const handleOpen = async(objectif) => {
  const response = await axios.get(`https://backend-codeschool241.onrender.com/briefs/activity/${objectif.id_objectif}`);
  const response2 = await axios.get(`https://backend-codeschool241.onrender.com/reponses`);
  
  setBrief(response.data[0]); 
  setNumber(response.data)// Définir le brief correspondant
  const data =[]
  console.log(response2.data);
  console.log(response.data);
  
  for (let index = 0; index < response.data.length; index++) {
    const answers = response2.data.filter((answer) => answer.id_brief === response.data[index].id);
    data.push(answers[answers.length-1])
    setAllResponses(data); // Définir le brief correspondant
    console.log(data);
    
  }
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};
  const [selectedReponse, setSelectedReponse] = React.useState(null);
  const [IdBrief, setIdBrief] = React.useState({});
  const [isResponseDialogOpen, setIsResponseDialogOpen] = React.useState(false); // État pour le second dialogue
  const [student, setstudent] = React.useState(null);
  const [message, setMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [newStudentsSkills, setnewStudentsSkills] = React.useState(null);
  
  
const handleResponseDialogOpen = (reponse) => {
  console.log("ID du brief associé :", reponse.id_brief); // Affiche id_brief en console
  setSelectedReponse(reponse);
  setIdBrief(reponse.id_brief)
  setIsResponseDialogOpen(true);
  

  const matchingEtudiant = classe.find(
    (etudiant) => etudiant.id === reponse.id_etudiant
  );
  setstudent(matchingEtudiant)
  const relatedBrief = number.find(
    (brief) => brief.id === reponse.id_brief
  );
  setBrief(relatedBrief)

    const newskills = JSON.parse(relatedBrief.competences)
    const oldskills = matchingEtudiant.competences

    if (newskills !== null || newskills?.length > 0) {
      const mergedSkills = [
        ...newskills,
        ...oldskills.filter(
          oldSkill => !newskills.some(newSkill => newSkill.titre === oldSkill.titre)
        )
      ];
      console.log(mergedSkills);
      setnewStudentsSkills(mergedSkills)
    }




  
};


const handleResponseDialogClose = () => {
  setIsResponseDialogOpen(false);
  setSelectedReponse(null);
};

const handleDelete = async () => {
  if (!brief.titre) {
    alert('Veuillez entrer un titre pour supprimer le brief.');
    return;
  }

  setIsLoading(true);

  try {
    const response = await axios.delete('https://backend-codeschool241.onrender.com/briefs', {
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        id_objectif: brief.id_objectif,
      },
    });
    const response2 = await axios.delete('https://backend-codeschool241.onrender.com/objectif', {
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        id_objectif: brief.id_objectif,
      },
    });

    setMessage(`Brief supprimé avec succès. Nombre de briefs affectés : ${response.data.affectedRows}`);
  } catch (error) {
    console.error('Erreur lors de la suppression du brief :', error);
    setMessage('Erreur lors de la suppression du brief.');
  } finally {
    setIsLoading(false);
    // window.location.reload();
  }
};

const handleValidateBrief = async (id, statut) => {

  setIsLoading(true);

  

  try {
    const response = await axios.put(
      `https://backend-codeschool241.onrender.com/brief/${IdBrief}`,
      { statut },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    console.log(student.id);
    const data = JSON.stringify(newStudentsSkills)
    console.log(data);

    
   

    

    let isValidated = null; // Initialisation avant les conditions

    if (statut === 2) {
      isValidated = "validé"; 
      await axios.put(
        `https://backend-codeschool241.onrender.com/skills/etudiant/${student.id}`,
        { competences: data },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      // Affectation sans redéclaration
    }
    if (statut === 3) {
      isValidated = `invalidé`; // Affectation sans redéclaration
    }

    const matchingEtudiant = classe.find(
      (etudiant) => etudiant.id === student.id
      );
    const currentTimeGMT1Libreville = new Date();
    currentTimeGMT1Libreville.setHours(currentTimeGMT1Libreville.getHours() + 1);
    await axios.post('https://backend-codeschool241.onrender.com/activites', {
      id_user:filteredUsers[0].id, 
      role:'formateur', 
      action:`a ${isValidated} la reponse à l\'activité \"${brief.titre}\" de ${matchingEtudiant.prenom} ${matchingEtudiant.nom}.`,
      date: currentTimeGMT1Libreville
    })

    



    await axios.post('https://backend-codeschool241.onrender.com/send-email/notification', {
      to: matchingEtudiant.email,
      subject: `Réponse à l’activité "${brief.titre}!`,
      text: `Hello ${matchingEtudiant.prenom},\n\nle statut de la réponse à ton activité "${brief.titre}" est ${isValidated} ${statut===3 ? `\n Envoie de nouveau ta réponse avant le ${brief.date}.`:'.'}`,
    });



    if (response.status === 200) {
      setMessage('Le statut du brief a été mis à jour avec succès.');
      window.location.reload(); // Rechargez la page pour actualiser les données
    } else {
      setMessage('Erreur lors de la mise à jour du statut du brief.');
    }
  } catch (error) {
    console.error('Erreur lors de la mise à jour du statut du brief:', error);
    setMessage('Erreur lors de la mise à jour du statut.');
  } finally {
    setIsLoading(false);
    // window.location.reload();
  }
};
  return (
    <div style={{width:'100%'}}>
      {modules.map((module) => {
        const objectifsfiltrés = objectifs.filter(objectif => objectif.id_module === module.id_module);
        

        const start = parseDate(module.debut);
        const end = parseDate(module.fin);
        const diffInMs = end - start;
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

        let totalValeur = 0;
        objectifsfiltrés.forEach((objectif) => {
          const filteredbriefs = briefs.filter(module => module.id_objectif === objectif.id_objectif);
          const uniqueBriefs = [];
          const studentIds = new Set();

          filteredbriefs.forEach(brief => {
            if (!studentIds.has(brief.id_etudiant)) {
              studentIds.add(brief.id_etudiant);
              uniqueBriefs.push(brief);
            }
          });

          const briefWithStatus1 = filteredbriefs.filter(module => module.statut === 2);
          const valeur = Math.floor(briefWithStatus1.length / uniqueBriefs.length * 100);
          totalValeur += valeur;
        });

        const moyenneValeur = objectifsfiltrés.length > 0 ? Math.floor(totalValeur / objectifsfiltrés.length) : 0;
    return (
          <Accordion key={module.id_module} expanded={expanded === `panel${module.id_module}`} onChange={handleChange(`panel${module.id_module}`)}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row", // Passe en colonne sur mobile
        justifyContent: "space-between",
        alignItems: isMobile ? "flex-start" : "center",
        width: "100%",
        gap: isMobile ? "10px" : "0", // Ajoute un espacement en mode colonne
      }}
    >
      <Typography>{module.module}</Typography>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row", // Gère l'alignement des enfants aussi
          gap: isMobile ? "10px" : "0",
          width: isMobile ? '100%' : 'fit-content',
          justifyContent:'end'
        }}
      >
        <span style={{ margin: isMobile ? "0" : "0 5px 0px 20px" }}>
          {isNaN(moyenneValeur) ? 0 : moyenneValeur}%
        </span>
        <div style={{ width: isMobile ? "100%" : "200px" }}> {/* Barre pleine largeur sur mobile */}
          <ProgressBar value={isNaN(moyenneValeur) ? 0 : moyenneValeur} />
        </div>
      </div>
    </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontWeight: 'bold' }}>Description:</Typography>
              <Typography>{module.descriprion}</Typography>
              <br />
              <Typography sx={{ fontWeight: 'bold' }}>Durée:</Typography>
              <Typography>{diffInDays} jours (Du {module.debut} au {module.fin})</Typography>
              <br />
              <Typography sx={{ fontWeight: 'bold' }}>Objectifs:</Typography>
      <div style={{ width: '100%' }}>
        {objectifsfiltrés.map((objectif) => {
          const filteredbriefs = briefs.filter(module => module.id_objectif === objectif.id_objectif);
          const uniqueBriefs = [];
          const studentIds = new Set();

          filteredbriefs.forEach(brief => {
            if (!studentIds.has(brief.id_etudiant)) {
              studentIds.add(brief.id_etudiant);
              uniqueBriefs.push(brief);
            }
          });

          const briefWithStatus1 = filteredbriefs.filter(module => module.statut === 2);
          // console.log(briefs);
          
          const valeur = isNaN(Math.floor((briefWithStatus1.length / uniqueBriefs.length) * 100))
          ? 0
          : Math.floor((briefWithStatus1.length / uniqueBriefs.length) * 100);


                  
          return (
            <div key={objectif.id_objectif}>
              <ListItemButton
                sx={{ width: '100%', padding: '8px 8px' }}
                onClick={() => handleOpen(objectif)} // Ouvre la popup avec les données de l'objectif
              >
                <div className='main-progress-box'>
                  <div style={{ display: 'flex', alignContent: 'center', margin: '10px 0', width: '100%' }}>
                    <CheckCircleOutlineIcon />
                    <span style={{ marginLeft: '15px' }}>
                    {`${objectif.objectif}`.length > (isMobile ? 27 : 70) 
                      ? `${`${objectif.objectif}`.slice(0, isMobile ? 27 : 70)}...` 
                      : `${objectif.objectif}`}
                    </span>
                  </div>
                  <div style={{ height: '50px', width: '50px' }}>
                    <Gauge
                      width={100}
                      height={100}
                      value={valeur}
                      style={{ height: '100%', width: '100%' }}
                      className='GlobalGauge'
                    />
                  </div>
                  
                </div>

              </ListItemButton>
              <Divider />
              <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                  {brief?.titre || 'Titre non disponible'}
                </DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                  })}
                >
                  <CloseIcon />
                </IconButton>

                {brief ? (
                <>
                  <DialogContent dividers>
                    <span style={{ fontWeight: 'bold' }}>Description :</span>
                    <p>{brief.description || 'Message non disponible'}</p>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '95%' }}>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>Date de livraison :</span>
                        <p>{brief.date || 'Date non disponible'}</p>
                      </div>
                      <div>
                        <span style={{ fontWeight: 'bold' }}>Pièce jointe :</span>
                        <br />
                        {brief.source ? (
                          <Button variant="outlined" startIcon={<DownloadIcon />} href={brief.source} download={brief.source}>
                            Télécharger le fichier
                          </Button>
                        ) : (
                          <p>Aucune pièce jointe</p>
                        )}
                      </div>
                    </div>
                    <br />  
                    <span style={{ fontWeight: 'bold' }}>Apprenants :</span>
                    <div style={{ display: 'flex', flexWrap: 'wrap', paddingBottom: '50px', marginTop: '7px' }}>
                                    {number.map((label) => {
                                      const etudiant = classe.find((item) => item.id === label.id_etudiant);
                                      if (!etudiant) {
                                        console.error(`Aucun étudiant trouvé pour l'id : ${label.id_etudiant}`);
                                        return null;
                                      }
                                      
                                      return (
                                        <div
                                          key={label.id_etudiant}
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '10px 20px 10px 10px',
                                            backgroundColor: '#121212',
                                            borderRadius: '50px',
                                            width: 'fit-content',
                                            marginRight: '15px',
                                            marginBottom: '10px',
                                            border: '1px solid rgb(48 48 48)',
                                          }}
                                        >
                                          <Avatar alt={`${etudiant.prenom}`} src={`${etudiant.photo}`} sx={{ marginRight: '15px' }} />
                                          {etudiant.prenom}
                                        </div>
                                      );
                                    })}
                                  </div>

                    <span style={{ fontWeight: 'bold' }}>Réponses :</span>
                    <br />
                    <div>
                      {allResponses && allResponses.length > 0 ? (
                        allResponses.map((reponse, index) => {
                          if (!reponse) return null; // Sécurité supplémentaire

                          const etudiant = classe?.find((etd) => etd.id === reponse.id_etudiant);
                          const briefReponse = briefs?.find((b) => b.id === reponse.id_brief);

                          const prenom = etudiant?.prenom || 'Nom non disponible';
                          const statutBrief = briefReponse?.statut ?? 'Statut inconnu';

                          const renderStatus = (statut) => {
                            if (statut === 1) return <span style={{ color: 'white' }}>En attente</span>;
                            if (statut === 2) return <span style={{ color: 'green' }}>Validé</span>;
                            if (statut === 3) return <span style={{ color: 'red' }}>Invalidé</span>;
                            return <span style={{ color: 'gray' }}>Statut inconnu</span>;
                          };

                          return (
                            <ListItemButton key={index} sx={{ paddingLeft: 0, paddingRight: 0 }} onClick={() => handleResponseDialogOpen(reponse)} // Ouvre le second Dialogue
              >
                              <div style={{ padding: '4px 0', width: '100%' }}>
                                <div style={{ padding: '5px 5px', display: 'flex', justifyContent: 'space-between' }}>
                                  <span>{prenom}</span>
                                  <span>{renderStatus(statutBrief)}</span>
                                </div>
                                <Divider />
                              </div>
                            </ListItemButton>
                          );
                        })
                      ) : (
                        <p>Aucune réponse disponible.</p>
                      )}
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <DeleteBrief handleDelete={handleDelete} />
                  </DialogActions>

                        {/* Second Dialogue pour afficher les détails d'une Réponse */}
                        <BootstrapDialog
                          onClose={handleResponseDialogClose}
                          aria-labelledby="response-dialog-title"
                          open={isResponseDialogOpen}
                        >
                          <DialogTitle id="response-dialog-title" sx={{ minWidth: '40vw' }}>
                            Détails de la réponse
                          </DialogTitle>
                          <IconButton
                            aria-label="close"
                            onClick={handleResponseDialogClose}
                            sx={(theme) => ({
                              position: 'absolute',
                              right: 8,
                              top: 8,
                              color: theme.palette.grey[500],
                            })}
                          >
                            <CloseIcon />
                          </IconButton>
                          {selectedReponse && (
                            <DialogContent dividers>
                              {(() => {
                                const matchingEtudiant = classe.find(
                                  (etudiant) => etudiant.id === selectedReponse.id_etudiant
                                );
                                
                  
                  
                  
                  
                                return (
                                  <p>
                                    <strong>Prénom :</strong>{' '}
                                    {matchingEtudiant ? matchingEtudiant.prenom : 'Nom non disponible'}
                                  </p>
                                );
                              })()}
                              <p><strong>Message :</strong> {selectedReponse.message}</p>
                              <p>
                                <strong>Lien :</strong>{' '}
                                <a href={selectedReponse.lien} target="_blank" rel="noopener noreferrer">
                                  Voir le document
                                </a>
                              </p>
                            </DialogContent>
                          )}
                  
                          {/* Vérification du statut du brief lié à la réponse */}
                          {selectedReponse && (() => {
                            const relatedBrief = number.find(
                              (brief) => brief.id === selectedReponse.id_brief
                            );
                            
                  
                            // Si le statut du brief est égal à 1, afficher les boutons
                            return relatedBrief?.statut === 1 ? (
                              <DialogActions>
                                <Button
                                  onClick={() => handleValidateBrief(IdBrief, 3)}
                                  sx={{ color: 'red' }}
                                >
                                  Invalider
                                </Button>
                                <Button
                                  onClick={() => handleValidateBrief(IdBrief, 2)}
                                  sx={{ color: 'white', backgroundColor: 'green' }}
                                >
                                  Valider
                                </Button>
                              </DialogActions>
                            ) : null;
                          })()}
                        </BootstrapDialog>
                </>
              ) : (
                <p>Les données ne sont pas disponibles.</p>
              )}

              </BootstrapDialog>


            </div>
          );
        })}



              <ListItemButton
                sx={{
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  },
                  padding: '8px 8px',
                }}
                onClick={() => {setShowForm(!showForm);handleClick(module)}}
              >
                <div>
                  <div className='main-progress-box' style={{color: '#ED462F' }}>
                    <div style={{ display: 'flex', alignContent: 'center', margin: '10px 0' }}>
                      <AddCircleOutlineIcon />

                      <Typography sx={{ marginLeft:'15px'}}>
                        {showForm ? 'Annuler' : 'Ajouter un objectif'}
                      </Typography>
                    </div>

                  </div>
                </div>
              </ListItemButton>



        

        {/* Formulaire pour ajouter un nouvel objectif */}
        {showForm && (
         <div style={{ marginTop: "15px" }}>

         <AddActivity
         classe={classe} 
         filteredUsers={filteredUsers} 
         skills={skills}
         setBriefs={setActivities}
         briefs={activities}
         module={module}
         ajouterObjectif={ajouterObjectif}
         setNewObjectif={setNewObjectif}
         newObjectif={newObjectif}
         idObjectifAdded={idObjectifAdded}
         />
       </div>
        )}
      </div>
              <br />
              <Typography sx={{ fontWeight: 'bold' }}>Compétence(s) abordée(s):</Typography>
              {Array.isArray(JSON.parse(module.competences)) &&
              JSON.parse(module.competences).map((competence, index) => (
                <span key={index}>
                  {competence}
                  {index === module.competences.length - 1 ? "." : ", "}
                </span>
              ))}

            </AccordionDetails>
          </Accordion>
        );
      })}
      <br />
      <Accordion 
        expanded={expanded === 'addModule'} 
        onChange={handleChange('addModule')} 
        sx={{
          backgroundColor: '#1E1E1E', // Couleur de fond différente
          border: '2px solid #1E1E1E', // Bordure spécifique
          '& .MuiAccordionSummary-content': {
            justifyContent: 'center', // Centrer le titre
            textAlign: 'center', // Centrer le texte
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            justifyContent: 'center', // Centrer l'icône
          },
          width:'100%'
        }}
      >
        <AccordionSummary>
          <Typography sx={{ fontWeight: 'bold', color: '#ED462F' }}>Ajouter un nouveau module</Typography>
        </AccordionSummary>
        <AccordionDetails>


        {/* Formulaire d'ajout de module */}
        <div style={{ marginTop: '20px' }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {/* Étape 1 */}
          <Step>
            <StepLabel>Informations générales</StepLabel>
            <StepContent>
              <TextField
                label="Nom du module"
                fullWidth
                margin="normal"
                value={newModule.module}
                onChange={(e) => handleInputChange('module', e.target.value)}
              />
              <TextField
                label="Description"
                fullWidth
                margin="normal"
                value={newModule.descriprion}
                onChange={(e) => handleInputChange('descriprion', e.target.value)}
              />
              <TextField
                label="Date de début"
                type="date"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                value={newModule.debut}
                onChange={(e) => handleInputChange('debut', e.target.value)}
              />
              <TextField
                label="Date de fin"
                type="date"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                value={newModule.fin}
                onChange={(e) => handleInputChange('fin', e.target.value)}
              />
              <div style={{ marginTop: '20px' }}>
                <Button variant="contained" onClick={handleNext}>
                  Suivant
                </Button>
              </div>
            </StepContent>
          </Step>

          {/* Étape 2 */}
          <Step>
            <StepLabel>Choix des compétences abordées</StepLabel>
            <StepContent>
            <div>
              <Grid container spacing={2}>
                {competencesList.map((competence, index) => (
                  <Grid item xs={4} key={index}> {/* 4 éléments pour 3 colonnes */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newModule.competences.includes(competence)}
                          onChange={() => toggleCompetence(competence)}
                        />
                      }
                      label={competence}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>

              <div style={{ marginTop: '20px' }}>
                <Button onClick={handleBack} style={{ marginRight: '10px' }}>
                  Retour
                </Button>
                <Button variant="contained" onClick={handleNext}>
                  Suivant
                </Button>
              </div>
            </StepContent>
          </Step>

          {/* Étape 3 */}
          <Step>
            <StepLabel>Résumé</StepLabel>
            <StepContent>
              <Typography>
                <strong>Nom du module :</strong> {newModule.module}
              </Typography>
              <br />
              <Typography>
                <strong>Description :</strong> {newModule.descriprion}
              </Typography>
              <br />
              <Typography>
                <strong>Dates :</strong> {formatDate(newModule.debut)} - {formatDate(newModule.fin)}
              </Typography>
              <br />
              <Typography>
                <strong>Compétences abordées :</strong>{' '}
                {newModule.competences.length > 0
                  ? newModule.competences.join(', ')
                  : 'Aucune compétence sélectionnée.'}
              </Typography>
              <Divider style={{ margin: '20px 0' }} />
              <div>
                <Button onClick={handleBack} style={{ marginRight: '10px' }}>
                  Retour
                </Button>
                <Button variant="contained" color="primary" onClick={handleAddModule}>
                  Valider
                </Button>
              </div>
            </StepContent>
          </Step>
        </Stepper>
      </div>

        </AccordionDetails>
      </Accordion>
                             {/* Notification */}
                             <Snackbar
           open={notification.open}
           autoHideDuration={4000}
           onClose={handleCloseNotification}
           anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
         >
           <Alert onClose={handleCloseNotification} severity={notification.type} variant="filled">
             {notification.message}
           </Alert>
         </Snackbar>


    </div>
  );
}
