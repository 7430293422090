import * as React from 'react';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Avatar from '@mui/material/Avatar';
import '../../App.css';

export default function NewBriefCheckout({fileLink, datas, newObjectif, competencesList, fichier }) {
  // Vérification que datas[1] et la date sont valides
  console.log(fileLink);
  
  const date = datas[1]?.date;
  const formattedDate = date
    ? `${date.$D ?? 'N/A'}/${date.$M+1 ?? 'N/A'}/${date.$y ?? 'N/A'}`
    : 'Date non disponible';
    

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <h2 className="boldtitle">Vérification</h2>

      <TableContainer sx={{ maxHeight: 600 }}>
        <div style={{ marginBottom: '16px' }}>
          <span style={{ fontWeight: 'bold' }}>Titre:</span>
          <p>{newObjectif || 'Titre non disponible'}</p>
        </div>

        <div style={{ marginBottom: '16px' }}>
          <span style={{ fontWeight: 'bold' }}>Description:</span>
          <p>{datas[1]?.message || 'Message non disponible'}</p>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <div>
            <span style={{ fontWeight: 'bold' }}>Date de livraison:</span><br />
            <p>{formattedDate}</p>
          </div>
          <div>
            <span style={{ fontWeight: 'bold' }}>Pièce jointe:</span><br />
            <p>{fichier}</p>
          </div>
        </div>

        <div>
          <span style={{ fontWeight: 'bold' }}>Apprenants:</span><br />
          <div style={{ display: 'flex', flexWrap: 'wrap', paddingBottom: '50px', marginTop: '7px' }}>
            {datas[0]?.map((label) => (
              <div
                key={label.id}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px 20px',
                  backgroundColor: '#121212',
                  borderRadius: '50px',
                  width: 'fit-content',
                  marginRight: '15px',
                  marginBottom: '10px',
                  border: '1px solid rgb(48, 48, 48)',
                }}
              >
                <Avatar alt={label.prenom} src={label.photo || '/static/images/avatar/1.jpg'} sx={{ marginRight: '15px' }} />
                {label.prenom}
              </div>
            ))}
          </div>
        </div>
        { competencesList.length > 0 &&
        <div style={{marginBottom:'16px'}}>
          <span style={{ fontWeight: 'bold' }}>Compétence(s) à valider:</span> <br />
          {Array.isArray(competencesList) &&
          competencesList.map((competence, index) => (
            <span key={competence.id}>
            {competence.titre} <em style={{fontSize:'13px', fontWeight:'bold'}}>(Niveau {competence.niveau})</em>
            {index === competencesList.length - 1 ? "." : ", "}
            </span>
          ))}
        </div>
        }
      </TableContainer>
    </Paper>
  );
}
