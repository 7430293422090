import React, { useState, useEffect } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const ActivityForm = ({
    formData,
    skills,
    handleChangeTitre,
    handleChange,
    handleDateChange,
    modules,
    handleChangeModules,
    arraymodules = [],
    objectifs,
    isSelectEnabled,
    handleChangeObjectifs,
    handleFileChangeFile,
    file,
    arrayobjectifs = [],
    competencesList, 
    setCompetencesList,
    VisuallyHiddenInput,
    ajouterObjectif, 
    module, 
    newObjectif, 
    setNewObjectif,
    ajouterActivite
}) => {
    const [showCompetences, setShowCompetences] = useState(false);
    const [activeCompetences, setActiveCompetences] = useState({});

    const toggleCompetence = (id, index) => {
        setActiveCompetences((prev) => {
            const isActive = prev[id];
            const updatedCompetences = { ...prev, [id]: isActive ? undefined : 1 };
            updateCompetencesList(updatedCompetences);
            return updatedCompetences;
        });
    };

    const changeLevel = (id, delta) => {
        setActiveCompetences((prev) => {
            const newLevel = Math.min(3, Math.max(1, (prev[id] || 1) + delta));
            const updatedCompetences = { ...prev, [id]: newLevel };
            updateCompetencesList(updatedCompetences);
            return updatedCompetences;
        });
    };

    const updateCompetencesList = (competences) => {
        const updatedList = Object.entries(competences)
            .filter(([_, level]) => level !== undefined)
            .map(([key, level]) => ({ titre: JSON.parse(module.competences)[key], niveau: level }));
        setCompetencesList(updatedList);
    };

    const handleSwitchChange = () => {
        if (showCompetences) {
            setActiveCompetences({});
            setCompetencesList([]);
        }
        setShowCompetences((prev) => !prev);
    };

    useEffect(() => {
        console.log(competencesList);
        console.log(JSON.parse(module.competences));
    }, [competencesList]);

    return (
        <div className='case0'>
            <h2 className='boldtitle' style={{ marginTop: '0' }}>Détails de l'activité</h2>

            <TextField
            label="Titre"
            value={newObjectif}
            onChange={(e) => setNewObjectif(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ width: '100%', marginBottom: '15px', mt: '15px' }}
            />            
            <TextField
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={6}
                name="message"
                value={formData.message}
                onChange={handleChange}
                sx={{ width: '100%', marginBottom: '15px' }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Date de livraison"
                    value={formData.date}
                    onChange={handleDateChange}
                    sx={{ width: '100%' }}
                />
            </LocalizationProvider>

            <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                sx={{ width: '100%', marginTop: '15px' }}
            >
                Ajouter un fichier
                <VisuallyHiddenInput type="file" onChange={handleFileChangeFile} />
            </Button>
            <div style={{ height: '30px', display: 'flex', justifyContent: 'center' }}>
                <span style={{ textAlign: 'center', width: '100%', fontSize: '18px' }}>
                    {file ? file.name : 'Aucun fichier sélectionné'}
                </span>
            </div>

            <FormControlLabel
                control={<Switch checked={showCompetences} onChange={handleSwitchChange} />}
                label="Des compétences à valider?"
                sx={{ marginTop: '20px' }}
            />

            {showCompetences && (
                <div style={{ marginTop: '15px' }}>
                    {JSON.parse(module.competences).map((competence, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <div>
                                <Checkbox
                                    checked={!!activeCompetences[index]}
                                    onChange={() => toggleCompetence(index, competence)}
                                />
                                <span style={{ color: activeCompetences[index] ? '#ED462F' : 'gray' }}>{competence}</span>
                            </div>
                            {activeCompetences[index] && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button onClick={() => changeLevel(index, -1)} disabled={activeCompetences[index] === 1}>-</Button>
                                    <span style={{ margin: '0 10px' }}>{activeCompetences[index]}</span>
                                    <Button onClick={() => changeLevel(index, 1)} disabled={activeCompetences[index] === 3}>+</Button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default ActivityForm;
