import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import '../../App.css';
import AddBrief from './AddBrief';
import AddActivity from './AddActivity';
import Briefs from './Briefs';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function AdminSecondModule({ classe, filteredUsers, skills, briefs, setBriefs }) {


  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

    <Box sx={{ width: '100%' }}>

        <Grid container spacing={3} className='row1'>
          
          <AddActivity 
          classe={classe} 
          filteredUsers={filteredUsers} 
          skills={skills}
          setBriefs={setBriefs}
          briefs={briefs}
          />


        </Grid>
      {/* <CustomTabPanel value={value} index={1}>
        <Grid container spacing={3} className='row1'>
          
          <AddBrief classe={classe}/>
          <Briefs 

          classe={classe}
          open={open}
          setOpen={setOpen}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          />

        </Grid>
      </CustomTabPanel> */}
    </Box>






      
    </Container>
  );
}

export default AdminSecondModule;
