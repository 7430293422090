import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import "./CurrentTopStudents.css"
import { io } from 'socket.io-client';

const socket = io('https://backend-codeschool241.onrender.com', {
  transports: ['websocket', 'polling'],
});

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}


function preventDefault(event) {
  event.preventDefault();
}

export default function CurrentTopStudents({filteredUsers, classe}) {

   const [topstudents, settopstudents] = React.useState([]);
    const [allBriefs, setAllBriefs] = React.useState([]);

    React.useEffect(() => {
      const fetchData = async () => {
        const promo = filteredUsers[0]?.promo;
        const localReferentiel = filteredUsers[0]?.referentiel;
    
        if (promo && localReferentiel) {
          try {
            socket.emit('getallbriefs', { referentiel: localReferentiel, promo });
          } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
          }
        }
      };
    
      fetchData();
    
      socket.on('allbriefsList', (data) => {
        if (!Array.isArray(classe)) return [];

        const filteredBriefs = data.filter((brief) =>
          classe.some((etudiant) => etudiant.id === brief.id_etudiant)
        );

    
        setAllBriefs(filteredBriefs);
        console.log("Briefs filtrés :", filteredBriefs);
    
        // Compter les briefs et les briefs validés pour chaque étudiant
        const etudiantsBriefs = {};
    
        filteredBriefs.forEach((brief) => {
          if (!etudiantsBriefs[brief.id_etudiant]) {
            etudiantsBriefs[brief.id_etudiant] = { 
              id_etudiant: brief.id_etudiant, 
              totalBriefs: 0, 
              validatedBriefs: 0 
            };
          }
          etudiantsBriefs[brief.id_etudiant].totalBriefs += 1;
          if (brief.statut === 2) {
            etudiantsBriefs[brief.id_etudiant].validatedBriefs += 1;
          }
        });
    
        // Transformer en tableau d'objets avec informations supplémentaires
        let etudiantsData = Object.values(etudiantsBriefs).map(etudiant => {
          const etudiantInfo = classe.find(e => e.id === etudiant.id_etudiant) || {};
    
          return {
            id_etudiant: etudiant.id_etudiant,
            nom: etudiantInfo.nom || "Inconnu",
            prenom: etudiantInfo.prenom || "Inconnu",
            specialisation: etudiantInfo.specialisation || "Non spécifiée",
            briefsValidated: `${etudiant.validatedBriefs}/${etudiant.totalBriefs}`,
            tauxValidation: etudiant.totalBriefs > 0 ? etudiant.validatedBriefs / etudiant.totalBriefs : 0,
            totalBriefs: etudiant.totalBriefs
          };
        });
    
        // Trier selon la stratégie
        etudiantsData.sort((a, b) => {
          if (b.tauxValidation !== a.tauxValidation) {
            return b.tauxValidation - a.tauxValidation; // Trier d'abord par taux de validation
          }
          return b.totalBriefs - a.totalBriefs; // En cas d'égalité, trier par nombre total de briefs
        });
    
        settopstudents(etudiantsData);
      });
    
      return () => {
        socket.off('allbriefsList');
      };
    }, [filteredUsers, classe]);
    
    
    
      
    
  return (
    <React.Fragment>
      <span style={{fontSize:'20px', fontWeight:'bold', textAlign:'start', color:'#ED462F', lineHeight:'14px', marginBottom:'25px'}}>Top 3 de la cohorte</span>
      <Table size="small">
        <TableHead>
          <TableRow>
            {/* <TableCell><strong>Noms</strong> </TableCell> */}
            <TableCell><strong>Prénoms</strong> </TableCell>
            <TableCell><strong>Spécialisation</strong> </TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {topstudents.slice(0, 3).map((row) => (
            <TableRow key={row.id}>
              {/* <TableCell sx={{ maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {row.nom}
              </TableCell> */}
              <TableCell sx={{ maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {row.prenom}
              </TableCell>
              <TableCell sx={{ maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {row.specialisation}
              </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more CurrentTopStudents
      </Link> */}
    </React.Fragment>
  );
}
