import React, { useState, useEffect, useCallback } from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import BriefApprenant from "./BriefApprenant";
import axios from "axios";

// Fonction pour convertir les dates au format JJ-MM-AAAA en un objet Date
const parseDate = (dateStr) => {
  const [day, month, year] = dateStr.split("-");
  return new Date(year, month - 1, day);
};

const today = new Date();

const BriefsApprenant = ({ classe, filteredUsers,setIsValidatingBox, isValidatingBox, handleCloseSettings }) => {
  const [briefs, setBriefs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getBriefs = useCallback(async () => {
    if (!classe || classe.length === 0) return;
    try {
      const response = await axios.get(
        `https://backend-codeschool241.onrender.com/briefs/${classe[0].id}`
      );
      setBriefs(response.data || []);
    } catch (error) {
      console.error("Erreur lors de la récupération des briefs:", error);
      setError("Une erreur est survenue lors du chargement des briefs.");
    } finally {
      setLoading(false);
    }
  }, [classe]);

  useEffect(() => {
    getBriefs();
  }, [getBriefs]);

  return (
    <Grid item xs={12} sm={12} md={7} lg={7} sx={{ pl: { sm: '16px', lg: '24px' } }}>
      <div className="briefsApprenant" style={{ width: '100%', height:'100%' }}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            maxWidth: '100%',
            boxSizing: 'border-box',
          }}
        >
          <h2 className="boldtitle">Liste des Briefs</h2>
          {loading ? (
            <Typography>Chargement des briefs...</Typography>
          ) : briefs.length === 0 ? (
            <Typography>Aucun brief pour le moment.</Typography>
          ) : (
            <TableContainer sx={{ maxHeight: 237, width: '100%' }}>
              <List sx={{ width: '100%', padding: 0 }}>
                {briefs.map((brief) => (
                  <div key={brief.id}>
                    <BriefApprenant
                      brief={brief}
                      parseDate={parseDate}
                      today={today}
                      classe={classe}
                      filteredUsers={filteredUsers}
                      handleCloseSettings={handleCloseSettings}
                      isValidatingBox ={isValidatingBox} 
                      setIsValidatingBox={setIsValidatingBox}
                    />
                    <Divider />
                  </div>
                ))}
              </List>
            </TableContainer>
          )}
        </Paper>
      </div>
    </Grid>
  );
};

export default BriefsApprenant;
