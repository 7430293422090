import * as React from 'react';
import Stack from '@mui/material/Stack';
import { Gauge } from '@mui/x-charts/Gauge';
import Title from '../Title';
import "./GlobalGauge.css"

export default function GlobalGauge({ classe }) {

  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (classe && classe.length > 0) {
      const sommeTotaleNiveaux = classe.reduce((accumulator, item) => {
        const totalNiveauItem = item.competences.reduce((acc, competence) => {
          return acc + competence.niveau;
        }, 0);
        return accumulator + totalNiveauItem;
      }, 0);
      

      // Vérifiez que `classe[0]` et `classe[0].competences` existent avant de les utiliser
      const totalCompetences = classe[0]?.competences?.length || 0;
      const pourcentage = Math.floor((sommeTotaleNiveaux / (totalCompetences * 3 * classe.length)) * 100);


      setValue(pourcentage);
    }
  }, [classe]); // Ajoutez `classe` comme dépendance pour réagir aux changements

  return (
    <Stack direction={{ xs: 'column', md: 'column' }} spacing={{ xs: 1, md: 3 }} style={{ height: '100%', width: '100%' }}>
      <span  style={{fontSize:'20px', fontWeight:'bold', textAlign:'left', color:'#ED462F', lineHeight:'14px'}}>Taux d'avancement <span style={{fontSize:'12px'}}> (global)</span></span>
      <Gauge width={100} height={100} value={value} style={{ height: '100%', width: '100%' }} className='GlobalGauge' />
    </Stack>
  );
}
