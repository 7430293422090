import React from 'react';
import { Box, IconButton, CircularProgress, responsiveFontSizes } from '@mui/material';
import { CheckCircle, Cancel } from '@mui/icons-material';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
    import { io } from 'socket.io-client';
import axios from 'axios';

    const socket = io('https://backend-codeschool241.onrender.com', {
      transports: ['websocket', 'polling'],
    });


const ResponseSlide = ({filteredUsers, classe}) => {
    const [responses, setResponses]= React.useState([])
    const [allbriefs, setAllbriefs]= React.useState([])

    React.useEffect(() => {
        const fetchData = async () => {
            const promo = filteredUsers[0]?.promo;
            const localReferentiel = filteredUsers[0]?.referentiel;
    
            if (promo && localReferentiel) {
                try {
                    socket.emit('getallbriefs', { referentiel: localReferentiel, promo });
                    socket.emit('getreponses');
                } catch (error) {
                    console.error("Erreur lors de la récupération des données :", error);
                }
            }
        };
    
        fetchData();
    
        socket.on('allbriefsList', (allBriefs) => {
                setAllbriefs(allBriefs);
           
    
            // Filtrer les briefs ayant un statut de 1
            const briefsStatut1 = allBriefs.filter(brief => brief.statut === 1);
            
            const validBriefsIds = new Set(briefsStatut1.map(brief => brief.id_brief));
            // console.log(briefsStatut1);

    
            socket.on('reponsesList', (reponses) => {
                if (!Array.isArray(reponses)) {
                    console.warn("Données reçues inattendues pour reponsesList :", reponses);
                    return;
                }
    
                // Filtrer les réponses dont id_brief est dans validBriefsIds

                let answers = []
                briefsStatut1.forEach((brief) => {
                    const filteredReponses = reponses.filter(rep => rep.id_brief === brief.id);
                    console.log(filteredReponses);
                    

    if (filteredReponses.length > 0) {
        answers.push(filteredReponses[filteredReponses.length-1]);

        // Si briefsStatut1.length === 1, on ajoute une deuxième fois
        if (briefsStatut1.length === 1) {
            answers.push(filteredReponses[filteredReponses.length-1]);
        }

        setResponses([...answers]); // Bien utiliser le spread operator pour éviter des problèmes d'état en React
    }
                });    
            });
        });

    }, [filteredUsers]);
    

 


    // return () => {
    //   socket.off('reponsesList');
    //   socket.off('allbriefsList');
    // };

    const truncateText = (text, maxLength) => 
        text.length > maxLength ? text.substring(0, maxLength) + '...' : text;

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "none", background: "red" }}
            onClick={onClick}
          />
        );
      }
      
      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "none", background: "green" }}
            onClick={onClick}
          />
        );
      }
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />

  };

    const [isValidating, setIsValidating] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [message, setMessage] = React.useState('');

    const handleValidateBrief = async (statut, data) => {

        let newStudentsSkills = null
        let student = data.student
        let reponse = data.response
        setIsLoading(true);
        let brief = allbriefs.find(brief => brief.id === Number(reponse.id_brief));
        console.log(brief);

        
        const newskills = JSON.parse(brief.competences)
        const oldskills = student.competences
    
        if (newskills !== null || newskills?.length > 0) {
          const mergedSkills = [
            ...newskills,
            ...oldskills.filter(
              oldSkill => !newskills.some(newSkill => newSkill.titre === oldSkill.titre)
            )
          ];
          console.log(mergedSkills);
          newStudentsSkills=mergedSkills
        }
        
      
        try {
          const response = await axios.put(
            `https://backend-codeschool241.onrender.com/brief/${reponse.id_brief}`,
            { statut },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
      
          console.log(student.id);
          const data = JSON.stringify(newStudentsSkills)
          console.log(data);
      
          
         
      
          
      
          let isValidated = null; // Initialisation avant les conditions
      
          if (statut === 2) {
            isValidated = "validé"; 
            await axios.put(
              `https://backend-codeschool241.onrender.com/skills/etudiant/${student.id}`,
              { competences: data },
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            );
            // Affectation sans redéclaration
          }
          if (statut === 3) {
            isValidated = `invalidé`; // Affectation sans redéclaration
          }
      
          const matchingEtudiant = classe.find(
            (etudiant) => etudiant.id === student.id
            );
          const currentTimeGMT1Libreville = new Date();
          currentTimeGMT1Libreville.setHours(currentTimeGMT1Libreville.getHours() + 1);
          await axios.post('https://backend-codeschool241.onrender.com/activites', {
            id_user:filteredUsers[0].id, 
            role:'formateur', 
            action:`a ${isValidated} la reponse à l\'activité \"${brief.titre}\" de ${matchingEtudiant.prenom} ${matchingEtudiant.nom}.`,
            date: currentTimeGMT1Libreville
          })
      
          
      
      
      
          await axios.post('https://backend-codeschool241.onrender.com/send-email/notification', {
            to: matchingEtudiant.email,
            subject: `Réponse à l’activité "${brief.titre}!`,
            text: `Hello ${matchingEtudiant.prenom},\n\nle statut de la réponse à ton activité "${brief.titre}" est ${isValidated} ${statut===3 ? `\n Envoie de nouveau ta réponse avant le ${brief.date}.`:'.'}`,
          });
      
      
      
          if (response.status === 200) {
            setMessage('Le statut du brief a été mis à jour avec succès.');
          } else {
            setMessage('Erreur lors de la mise à jour du statut du brief.');
          }
        } catch (error) {
          console.error('Erreur lors de la mise à jour du statut du brief:', error);
          setMessage('Erreur lors de la mise à jour du statut.');
        } finally {
          setIsLoading(false);
          window.location.reload()
        }
      };

    return (
        <>
            {responses.length > 0 ? (
                <Slider {...settings}>
                    {responses.map((response, index) => {
    // Filtrer la classe pour obtenir l'étudiant correspondant
    const student = classe.find(student => student.id === response.id_etudiant);
    
    // console.log(student);
    let data = {response, student}

    if (!student) return null; // Si aucun étudiant correspondant n'est trouvé, on ignore cet élément

    return (
                        <div key={index}>
                            <Box mt={3} sx={{ padding: 2, borderRadius: 2, height:'175px', pt:0,pb:0, display:'flex', flexDirection:'column', justifyContent:'space-between' }}>
                                <p style={{ fontSize: 14, color: '#fff', fontStyle: 'italic' }}>
                                    <strong>{student.prenom}:</strong> "{truncateText(response.message, 150)}"
                                </p>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <a 
                                        href={response.lien} 
                                        target="_blank" 
                                        rel="noopener noreferrer" 
                                        style={{ textDecoration: 'none', color: 'red', fontWeight: 'bold', textDecoration:'underline' }}
                                    >
                                        Voir le document
                                    </a>
                                    <div>
                                        <IconButton 
                                            onClick={() => handleValidateBrief(3, data)} 
                                            sx={{ color: 'red' }} 
                                            disabled={isValidating}
                                        >
                                            {isValidating ? <CircularProgress size={20} /> : <Cancel fontSize="large" />}
                                        </IconButton>

                                        <IconButton 
                                            onClick={() => handleValidateBrief(2, data)} 
                                            sx={{ color: 'green' }} 
                                            disabled={isValidating}
                                        >
                                            {isValidating ? <CircularProgress size={20} /> : <CheckCircle fontSize="large" />}
                                        </IconButton>
                                    </div>
                                </div>
                            </Box>
                        </div>
                        );
                    })}
                </Slider>
            ) : (
                <div style={{ textAlign: 'center', height:'175px',padding: '20px', color: '#fff', fontSize: '16px', fontStyle: 'italic', display:'flex', alignItems:'center', justifyContent:'center' }}>
                    Aucune réponse pour le moment.
                </div>
            )}
        </>
    );
};

export default ResponseSlide;
