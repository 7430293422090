import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import Slide from '@mui/material/Slide';
import InfoEtudiant from './InfoEtudiant';
import BriefsApprenant from './BriefsApprenant';
import PersonalProgress from './PersonalProgress';
import SpecialisationSwitch from './SpecialisationSwitch';
import { Divider } from '@mui/material';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Etudiant({ open, selectedStudent, isValidatingBox, setIsValidatingBox, handleClose, filteredUsers }) {
  const [openSettings, setOpenSettings] = React.useState(false);

  const handleOpenSettings = () => {
    setOpenSettings(true);
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };

  return (
    <React.Fragment>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar sx={{width:'100%', display:'flex', justifyContent:'space-between', padding:'0 25px'}}>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="fermer">
              <CloseIcon />
            </IconButton>
            <IconButton edge="end" color="inherit" onClick={handleOpenSettings} aria-label="paramètres">
              <SettingsIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className='infobriefs' style={{ display: 'flex', padding: '20px' }}>
          <InfoEtudiant selectedStudent={selectedStudent} />
          <BriefsApprenant isValidatingBox ={isValidatingBox} setIsValidatingBox={setIsValidatingBox} classe={[selectedStudent]} filteredUsers={filteredUsers} handleCloseSettings={handleCloseSettings} />
        </div>
        <div style={{ display: 'flex', padding: '20px', width: '100%' }}>
          <PersonalProgress selectedStudent={selectedStudent} />
        </div>
      </Dialog>
      
      <Dialog open={openSettings} onClose={handleCloseSettings}>
        <div style={{ padding: '20px', minWidth:'80%', maxWidth:"400px" }}>
          <h2>Paramètres</h2>
          <SpecialisationSwitch student={selectedStudent}/>
          <Divider/>
          <br /><br />
          <Button onClick={handleCloseSettings}>Fermer</Button>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
