import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, Avatar, Box, Typography } from '@mui/material';
import { Gauge } from '@mui/x-charts/Gauge';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookSharpIcon from '@mui/icons-material/FacebookSharp';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LanguageIcon from '@mui/icons-material/Language';
import GitHubIcon from '@mui/icons-material/GitHub';

function InfoEtudiant({ selectedStudent }) {
    const borderColor = selectedStudent.discipline >= 5 ? 'green' : 'red';
    const [value, setValue] = useState(0);
    const lien = selectedStudent.photo;

    useEffect(() => {
        if (!selectedStudent || !selectedStudent.competences) return;

        const sommeNiveaux = selectedStudent.competences.reduce((acc, comp) => acc + comp.niveau, 0);
        const nombreCompetences = selectedStudent.competences.length;
        const ratio = nombreCompetences ? Math.floor((sommeNiveaux / (nombreCompetences * 3)) * 100) : 0;
        
        setValue(ratio);
    }, [selectedStudent]);

    return (
        <Grid item xs={12} sm={10} md={6} lg={5}>
            {/* Bloc informations étudiant */}
            <Paper sx={{ p: 2, display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', sm: 'row' } }}>
                <Avatar
                    alt={`${selectedStudent.prenom} ${selectedStudent.nom}`}
                    src={lien}
                    sx={{ width: 100, height: 100, mr: { sm: 3 }, mb: { xs: 2, sm: 0 } }}
                />
                <Box sx={{ width: '100%' }} className='infoStud'>
                    <Typography variant="h6" fontWeight="bold">
                        {`${selectedStudent.prenom} ${selectedStudent.nom}`}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {selectedStudent.specialisation} • {selectedStudent.age} ans
                    </Typography>

                    {/* Icônes Réseaux et Contacts */}
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
                        {[ 
                            { href: `tel:00241${selectedStudent.phone}`, icon: <LocalPhoneIcon /> },
                            { href: selectedStudent.linkedin, icon: <LinkedInIcon /> },
                            { href: selectedStudent.facebook, icon: <FacebookSharpIcon /> },
                            { href: `mailto:${selectedStudent.email}`, icon: <AlternateEmailIcon /> },
                            { href: selectedStudent.github, icon: <GitHubIcon /> },
                            { href: selectedStudent.site, icon: <LanguageIcon /> }
                        ].map((item, index) => (
                            <a key={index} href={item.href} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                <Box sx={{
                                    width: 36, height: 36, display: 'flex', alignItems: 'center', justifyContent: 'center',
                                    backgroundColor: 'red', borderRadius: '50%', color: 'white'
                                }}>
                                    {item.icon}
                                </Box>
                            </a>
                        ))}
                    </Box>
                </Box>
            </Paper>

            {/* Bloc taux d'avancement & discipline */}
            <Paper sx={{ p: 2, mt: 2, display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-around', alignItems: 'center' }}>
                {/* Taux d'avancement */}
                <Box textAlign="center" sx={{display: 'flex',flexDirection: 'column',alignItems: 'center'}}>
                    <Typography variant="h6" fontWeight="bold" color="#ED462F">
                        Taux d'avancement
                    </Typography>
                    <Gauge width={100} height={100} value={value} />

                </Box>

                {/* Note de discipline */}
                <Box textAlign="center" sx={{display: 'flex',flexDirection: 'column',alignItems: 'center'}}>
                <Typography variant="h6" fontWeight="bold" color="#ED462F">
                Note de discipline
                    </Typography>
                    <Gauge width={100} height={100} value={selectedStudent.discipline} />

                </Box>
            </Paper>
        </Grid>
    );
}

export default InfoEtudiant;
