import React from 'react';
import { Grid, Paper, TableContainer, List, Divider, ListItemButton, Avatar, IconButton, useMediaQuery } from '@mui/material';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import '../../App.css';
import { io } from 'socket.io-client';
import axios from 'axios';

    const socket = io('https://backend-codeschool241.onrender.com', {
      transports: ['websocket', 'polling'],
    });

function ListApprenant({ classe, handleClickOpen, setSelectedStudent, toggleView, view, filteredUsers }) {
    const students = Array.isArray(classe) ? classe : [];
    const isSmallScreen = useMediaQuery('(max-width:600px)'); // Détection des petits écrans

    const selection = (id) => {
        const choosenOne = students.find((element) => element.id === id);
        setSelectedStudent(choosenOne);
        handleClickOpen();
    };
    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
    };
        const [responses, setResponses]= React.useState([])
            const [allbriefs, setAllbriefs]= React.useState([])

        React.useEffect(() => {
            const fetchData = async () => {
                const promo = filteredUsers[0]?.promo;
                const localReferentiel = filteredUsers[0]?.referentiel;
        
                if (promo && localReferentiel) {
                    try {
                        socket.emit('getallbriefs', { referentiel: localReferentiel, promo });
                        socket.emit('getreponses');
                    } catch (error) {
                        console.error("Erreur lors de la récupération des données :", error);
                    }
                }
            };
        
            fetchData();
        
            socket.on('allbriefsList', (allBriefs) => {
                    setAllbriefs(allBriefs);
               
        
                // Filtrer les briefs ayant un statut de 1
                const briefsStatut1 = allBriefs.filter(brief => brief.statut !== 2);
                setResponses(briefsStatut1)
                const validBriefsIds = new Set(briefsStatut1.map(brief => brief.id_etudiant));
                // console.log(briefsStatut1);
    
        

            });
    
        }, [filteredUsers]);
    return (
        <Grid item xs={12} md={8} >
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 'auto', minHeight: 350 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h2 className="boldtitle">Apprenants</h2>
                    <IconButton variant="contained" color="primary" onClick={toggleView}>
                        {view ? <PersonAddAlt1Icon /> : <CloseIcon />}
                    </IconButton>
                </div>

                {students.length > 0 ? (
                    <TableContainer sx={{ maxHeight: 350, overflowY: 'auto', width: '100%' }}>
                        <List sx={{ width: '100%', p: 0 }}>
                            {students.map((student) => {
                                const totalNiveau = student.competences.reduce((acc, competence) => acc + competence.niveau, 0);
                                const achieved = Math.floor((totalNiveau / (student.competences.length * 3)) * 100);
                                console.log(responses);
                                
                                const briefsWithStatutZero = responses.filter(brief => brief.id_etudiant === student.id).length;

                                return (
                                    <div key={student.id}>
                                        <ListItemButton onClick={() => selection(student.id)}>
                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', flexWrap: 'wrap' }}>
                                                {/* Avatar et nom */}
                                                <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1, minWidth: isSmallScreen ? '100%' : 'auto' }}>
                                                    <Avatar alt={student.prenom} src={student.photo} sx={{ mr: 2 }} />

                                                    <div style={{}}>
                                                        <span style={{ fontSize: isSmallScreen ? '16px' : '18px', fontWeight: '500', color: 'white', whiteSpace: 'nowrap' }}>
                                                        {`${student.prenom+' '+student.nom}`.length > (isSmallScreen ? 20 : 60) 
                                                        ? `${`${student.prenom+' '+student.nom}`.slice(0, isSmallScreen ? 20 : 60)}...` 
                                                        : `${student.prenom+' '+student.nom}`}
                                                        </span> 
                                                        <div style={{
                                                        display: isSmallScreen ? 'flex' : 'none',
                                                        justifyContent: 'space-between',
                                                        width: 'fit-content'
          
                                                    }}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <SentimentVerySatisfiedIcon sx={{ fontSize: '20px', color: '#ffffff' }} />
                                                            <span style={{ fontSize: '16px', color: 'white', marginLeft: '5px' }}>{student.discipline}</span>
                                                        </div>
                                                        <Divider orientation="vertical" flexItem sx={{ mx: 1, display: 'block' }} />
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <ClassOutlinedIcon sx={{ fontSize: '20px', color: '#ffffff' }} />
                                                            <span style={{ fontSize: '16px', color: 'white', marginLeft: '5px' }}>{briefsWithStatutZero}</span>
                                                        </div>
                                                        <Divider orientation="vertical" flexItem sx={{ mx: 1, display: 'block' }} />
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <ChecklistIcon sx={{ fontSize: '20px', color: '#ffffff' }} />
                                                            <span style={{ fontSize: '16px', color: 'white', marginLeft: '5px' }}>{achieved}%</span>
                                                        </div>
                                                        </div>                                                       
                                                    </div>


                                                </div>

                                                {/* Informations statistiques */}
                                                <div style={{
                                                    justifyContent: 'space-around',
                                                    width: isSmallScreen ? '100%' : '185px',
                                                    marginTop: isSmallScreen ? '10px' : 0,
                                                    display: isSmallScreen ? 'none' : 'flex'
                                                }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <SentimentVerySatisfiedIcon sx={{ fontSize: '16px', color: '#ffffff' }} />
                                                        <span style={{ fontSize: '16px', color: 'white', marginLeft: '5px' }}>{student.discipline}</span>
                                                    </div>
                                                    <Divider orientation="vertical" flexItem sx={{ mx: 1, display: isSmallScreen ? 'none' : 'block' }} />
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <ClassOutlinedIcon sx={{ fontSize: '16px', color: '#ffffff' }} />
                                                        <span style={{ fontSize: '16px', color: 'white', marginLeft: '5px' }}>{briefsWithStatutZero}</span>
                                                    </div>
                                                    <Divider orientation="vertical" flexItem sx={{ mx: 1, display: isSmallScreen ? 'none' : 'block' }} />
                                                    <div style={{ display: 'flex', alignItems: 'center', width:'55px' }}>
                                                        <ChecklistIcon sx={{ fontSize: '16px', color: '#ffffff' }} />
                                                        <span style={{ fontSize: '16px', color: 'white', marginLeft: '5px' }}>{achieved}%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ListItemButton>
                                        <Divider />
                                    </div>
                                );
                            })}
                        </List>
                    </TableContainer>
                ) : (
                    <div style={{ color: 'white', textAlign: 'center', marginTop: '20px' }}>
                        Il n'y a pas d'apprenant actuellement.
                    </div>
                )}
            </Paper>
        </Grid>
    );
}

export default ListApprenant;
