import * as React from 'react';
import io from 'socket.io-client';
import axios from 'axios';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

// Connexion au WebSocket
const socket = io('https://backend-codeschool241.onrender.com', {
  transports: ['websocket', 'polling'],
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function BriefApprenant({ brief,handleCloseSettings, parseDate,isValidatingBox, setIsValidatingBox, today, classe, setIsLoading, relatedBriefs, setMessage, filteredUsers }) {
  const [open, setOpen] = React.useState(false);
  const [allResponses, setAllResponses] = React.useState([]);
  const [selectedReponse, setSelectedReponse] = React.useState(null);
  const [isValidating, setIsValidating] = React.useState(false);
  const isMobile = useMediaQuery('(max-width:600px)')


  // Écouter les réponses via WebSocket
  React.useEffect(() => {
    socket.emit('getreponses');
    socket.on('reponsesList', (data) => {
      setAllResponses(data || []);
      // console.log('Réponses reçues :', data);
    });

    return () => {
      socket.off('reponsesList');
    };
  }, []);

  // Trouver l'étudiant correspondant
  const matchingEtudiant = classe.find((etudiant) => etudiant.id === brief.id_etudiant);

  // Filtrer la réponse correspondant au brief et à l'étudiant
  React.useEffect(() => {
    // console.log(brief);

    if (allResponses.length > 0) {
      const response = allResponses.filter(
        (response) => response.id_etudiant === brief.id_etudiant && response.id_brief === brief.id
      );
      
      setSelectedReponse(response[response.length-1] || null);
    }
  }, [allResponses, brief]);

  // Ouvrir / Fermer la boîte de dialogue
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Fonction pour valider / invalider une réponse
  const handleValidateBrief = async (statut, data) => {
    setIsValidatingBox(false)

    let newStudentsSkills = null
    let student = matchingEtudiant
    let reponse = selectedReponse
    // setIsLoading(true);


    
    const newskills = JSON.parse(brief.competences)
    const oldskills = student.competences

    if (newskills !== null || newskills?.length > 0) {
      const mergedSkills = [
        ...newskills,
        ...oldskills.filter(
          oldSkill => !newskills.some(newSkill => newSkill.titre === oldSkill.titre)
        )
      ];
      console.log(mergedSkills);
      newStudentsSkills=mergedSkills
    }
    
  
    try {
      const response = await axios.put(
        `https://backend-codeschool241.onrender.com/brief/${reponse.id_brief}`,
        { statut },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      console.log(student.id);
      const data = JSON.stringify(newStudentsSkills)
      console.log(data);
  
      
     
  
      
  
      let isValidated = null; // Initialisation avant les conditions
  
      if (statut === 2) {
        isValidated = "validé"; 
        await axios.put(
          `https://backend-codeschool241.onrender.com/skills/etudiant/${student.id}`,
          { competences: data },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        // Affectation sans redéclaration
      }
      if (statut === 3) {
        isValidated = `invalidé`; // Affectation sans redéclaration
      }
  
      const matchingEtudiant = classe.find(
        (etudiant) => etudiant.id === student.id
        );
      const currentTimeGMT1Libreville = new Date();
      currentTimeGMT1Libreville.setHours(currentTimeGMT1Libreville.getHours() + 1);
      await axios.post('https://backend-codeschool241.onrender.com/activites', {
        id_user:filteredUsers[0].id, 
        role:'formateur', 
        action:`a ${isValidated} la reponse à l\'activité \"${brief.titre}\" de ${matchingEtudiant.prenom} ${matchingEtudiant.nom}.`,
        date: currentTimeGMT1Libreville
      })
  
      
  
  
  
      await axios.post('https://backend-codeschool241.onrender.com/send-email/notification', {
        to: matchingEtudiant.email,
        subject: `Réponse à l’activité "${brief.titre}!`,
        text: `Hello ${matchingEtudiant.prenom},\n\nle statut de la réponse à ton activité "${brief.titre}" est ${isValidated} ${statut===3 ? `\n Envoie de nouveau ta réponse avant le ${brief.date}.`:'.'}`,
      });
  
  
      if (response.status === 200) {
        // setMessage('Le statut du brief a été mis à jour avec succès.');
      } else {
        // setMessage('Erreur lors de la mise à jour du statut du brief.');
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour du statut du brief:', error);
      // setMessage('Erreur lors de la mise à jour du statut.');
    } finally {
      // setIsLoading(false);

    }
  };

  return (
    <>
      <ListItemButton onClick={handleClickOpen}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%" p={1}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Typography variant="h6" noWrap sx={{ fontWeight: '600', color: 'white', fontSize: '18px' }}>
            {brief.titre.length > (isMobile ? 20 : 60) 
              ? `${brief.titre.slice(0, isMobile ? 20 : 60)}...` 
              : brief.titre}
          </Typography>

          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="fit-content">
            <Typography sx={{ fontSize: '16px', color: 'white', ml: 1 }}>
              {parseDate(brief.date) < today ? 'Terminé' : brief.date}
            </Typography>
          </Box>
        </Box>
      </ListItemButton>

      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {brief.titre}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Description:</Typography>
          <Typography variant="body2">{brief.description || 'Message non disponible'}</Typography>

          <Box display="flex" justifyContent="space-between" mt={2}>
            <Box>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Date de livraison:</Typography>
              <Typography variant="body2">{brief.date}</Typography>
            </Box>
            <Box>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Pièce jointe:</Typography>
              <Button variant="outlined" startIcon={<DownloadIcon />} href={brief.source} download>
                Télécharger le fichier
              </Button>
            </Box>
          </Box>

          {selectedReponse && (
            <Box mt={3} p={2} sx={{ border: '1px solid #ccc', borderRadius: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Détails de la réponse</Typography>
              <Typography><strong>Message :</strong> {selectedReponse.message}</Typography>
              <Typography>
                <strong>Lien :</strong>{' '}
                <a href={selectedReponse.lien} target="_blank" rel="noopener noreferrer">
                  Voir le document
                </a>
              </Typography>
            </Box>
          )}
        </DialogContent>
        {isValidatingBox &&(
          <>
        {brief.statut === 1 &&(
                  <DialogActions>
                  {selectedReponse && (
                    <>
                      <Button onClick={() => handleValidateBrief(3)} sx={{ color: 'red' }} disabled={isValidating}>
                        {isValidating ? <CircularProgress size={20} /> : 'Invalider'}
                      </Button>
                      <Button onClick={() => handleValidateBrief(2)} sx={{ color: 'white', backgroundColor: 'green' }} disabled={isValidating}>
                        {isValidating ? <CircularProgress size={20} /> : 'Valider'}
                      </Button>
                    </>
                  )}
                </DialogActions>
        )}</>
      )}

      </BootstrapDialog>
    </>
  );
}
