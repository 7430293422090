import React, { useState, useEffect } from 'react';
import ChangeInformations from './ChangeInformations';
import ChangePassword from './ChangePassword';
import SpecialisationSelector from './SpecialisationSelector';



function Profil({topstudents,classe, filteredUsers}) {
    
    return ( 
        <div>
            <ChangeInformations classe={classe} filteredUsers={filteredUsers}/>
            <SpecialisationSelector selectedStudent={filteredUsers[0]}  />
            <ChangePassword topstudents={topstudents} classe={classe} filteredUsers={filteredUsers}/>
        </div>
     );
}

export default Profil;