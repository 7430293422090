import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import '../../App.css';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TransferList from './TransferList';
import NewBriefCheckout from './NewBriefCheckout';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import ActivityForm from './ActvityForm';
import { io } from 'socket.io-client';

const socket = io('https://backend-codeschool241.onrender.com', {
  transports: ['websocket', 'polling'],
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function AddActivity({ classe, filteredUsers, skills, module, newObjectif, ajouterObjectif, setNewObjectif, idObjectifAdded, setLoading, setShowForm }) {
  const [file, setFile] = useState(null);
  const [competencesList, setCompetencesList] = useState([]);
  const [formData, setFormData] = useState({
    titre: newObjectif,
    message: '',
    date: null,
    file: null,
  });

  const [left, setLeft] = useState(classe || []);
  const [right, setRight] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [arraymodules, setArraymodules] = useState([]);
  const [selectedModule, setSelectedModule] = useState('');
  const [isSelectEnabled, setIsSelectEnabled] = useState(false);
  const [arrayobjectifs, setArrayobjectifs] = useState([]);
  const [selectedObjectif, setSelectedObjectif] = useState('');
  const [fichier, setFichier] = useState('');
  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState("");

  const handleChangeModules = (event) => {
    const getObjectifs = async () => {
      try {
        const response = await axios.get('https://backend-codeschool241.onrender.com/objectifs');
        const data = response.data.filter(obj => obj.id_module === event.target.value);
        setSelectedModule(event.target.value);
        setArrayobjectifs(data);
        setIsSelectEnabled(true);
      } catch (error) {
        console.error('Error fetching objectives:', error);
      }
    };
    getObjectifs();
  };

  const handleChangeObjectifs = (event) => {
    setSelectedObjectif(event.target.value);
    const objectif = arrayobjectifs.find(
      (obj) => obj.id_objectif === event.target.value
    );
    if (objectif) {
      setFormData(prevData => ({
        ...prevData,
        titre: objectif.objectif,
      }));
    }
  };

  const handleBack = () => {
    if (activeStep < 2) {
      setIsDisabled(false)

    }
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      message: value,
    }));
  };

  const handleChangeTitre = (e) => {
    const { value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      titre: value,
    }));
  };

  const handleDateChange = (newDate) => {
    setFormData(prevData => ({
      ...prevData,
      date: newDate,
    }));
  };

  const getData = async () => {
    try {
      if (classe && classe.length > 0) {
        const response = await axios.get('https://backend-codeschool241.onrender.com/modules');
        const data = response.data.filter(mod => mod.cohorte === classe[0].referentiel);
        const modules = data.filter(mod => mod.promo === classe[0].promo);
        setArraymodules(modules);
      }
    } catch (error) {
      console.error('Error fetching modules:', error);
    }
  };

  useEffect(() => {
    getData();
    console.log(module);
    
  }, [classe]);

  const handleFileChangeFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
  };

  const handleUpload = async () => {
    if (!file) return;

    const storageRef = ref(storage, `/files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          console.error("Upload error:", error);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setFichier(downloadURL);
            resolve(downloadURL);
          });
        }
      );
    });
  };
  const [isDisabled, setIsDisabled] = useState(false)
  const handleNext = async () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);

    if (activeStep > 1) {
      setIsDisabled(true)

    }
    if (activeStep === 1) {

      try {
        await handleUpload();
      } catch (error) {
        console.error("Error uploading file:", error);
        return;
      }
    }

  };



  
  const ajouterActivite = async (idObjectifAdded) => {
    ajouterObjectif(module)

    const response = await axios.get('https://backend-codeschool241.onrender.com/objectifs');
    const lastIndex = response.data[response.data.length-1]
    const id_objectif = lastIndex.id_objectif
    
    
        try {
      setIsLoading(true);

      const brief = {
        titre: newObjectif,
        date: formData.date ? `${formData.date.$D}-${formData.date.$M + 1}-${formData.date.$y}` : '',
        source: fichier,
        statut: 0,
        description: formData.message,
        id_etudiant: '',
        cohorte: classe[0]?.referentiel,
        id_objectif: id_objectif,  // Utilise l'id reçu en paramètre
        promo: filteredUsers[0]?.promo,
        competences: JSON.stringify(competencesList)
      };

      // Send briefs to students
      const promises = right.map((element) => {
        return axios.post('https://backend-codeschool241.onrender.com/briefs', { 
          ...brief, 
          id_etudiant: element.id 
        });
      });

      // Send notifications
      const promises2 = right.map((element) => {
        const matchingEtudiant = classe.find(etudiant => etudiant.id === element.id);
        if (!matchingEtudiant) return Promise.resolve();

        return axios.post('https://backend-codeschool241.onrender.com/send-email/notification', {
          to: matchingEtudiant.email,
          subject: `Nouvelle activité reçue !`,
          text: `Hello ${matchingEtudiant.prenom},\n\nTu as reçu une nouvelle activité (${newObjectif}) sur la plateforme Code School à rendre au plus tard le ${formData.date.$D}-${formData.date.$M + 1}-${formData.date.$y}.`,
        });
      });

      // Log activities
      const promises3 = right.map((element) => {
        const matchingEtudiant = classe.find(etudiant => etudiant.id === element.id);
        if (!matchingEtudiant) return Promise.resolve();

        const currentTimeGMT1Libreville = new Date();
        currentTimeGMT1Libreville.setHours(currentTimeGMT1Libreville.getHours() + 1);

        return axios.post('https://backend-codeschool241.onrender.com/activites', {
          id_user: filteredUsers[0]?.id,
          role: 'formateur',
          action: `a attribué l'activité "${newObjectif}" à ${matchingEtudiant.prenom} ${matchingEtudiant.nom}`,
          date: currentTimeGMT1Libreville
        });
      });

      await Promise.all([...promises, ...promises2, ...promises3]);
      setIsSuccess(true);

    } catch (error) {
      console.error("Error adding activity:", error);
      setIsSuccess(false);
    } finally {
      setFormData({
        titre: '',
        message: '',
        date: null,
        file: null,
      });
      setActiveStep(0);
      setIsLoading(false);
      setOpenSnackbar(true);
    }
  };
  
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <ActivityForm
            skills={skills}
            formData={formData}
            handleChangeTitre={handleChangeTitre}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            modules={selectedModule}
            handleChangeModules={handleChangeModules}
            arraymodules={arraymodules}
            objectifs={selectedObjectif}
            isSelectEnabled={isSelectEnabled}
            handleChangeObjectifs={handleChangeObjectifs}
            handleFileChangeFile={handleFileChangeFile}
            file={file}
            arrayobjectifs={arrayobjectifs}
            VisuallyHiddenInput={VisuallyHiddenInput}
            competencesList={competencesList}
            setCompetencesList={setCompetencesList}
            ajouterObjectif={ajouterObjectif}
            module={module}
            newObjectif={newObjectif}
            setNewObjectif={setNewObjectif}
            ajouterActivite={ajouterActivite}
          />
        );

      case 1:
        return (
          <div>
            <h2 className="boldtitle" style={{ marginTop: '0' }}>Destinataire(s)</h2>
            <TransferList
              classe={classe}
              setLeft={setLeft}
              setRight={setRight}
              left={left}
              right={right}
              module={module}
            />
          </div>
        );

      case 2:
        return (
          <NewBriefCheckout
            right={right}
            datas={[right, formData]}
            fichier={fileName}
            competencesList={competencesList}
            newObjectif={newObjectif}
            fileLink={fichier}
          />
        );

      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <Grid item xs={12} md={4} lg={12}>
      {getStepContent(activeStep)}
      <div style={{ height: '50px' }}>
      {!isDisabled ? (
        <>
        <MobileStepper
          variant="dots"
          steps={3}
          position="static"
          activeStep={activeStep}
          sx={{ maxWidth: '100%', flexGrow: 1, height: 'fit-content' }}
          nextButton={
            <div>
              {activeStep === 2 ? (
                <>
                {fichier.length > 0 ? (
                  <Button
                  variant="contained"
                  endIcon={isLoading ? <CircularProgress size={24} color="inherit" /> : <SendIcon />}
                  onClick={() => ajouterActivite()}
                  // disabled={isLoading || fichier.length === 0}
                >
                  Envoyer
                </Button>
                ) : (
                  <>
                  <span>Chargement...</span>
                  </>
                )
              }
                  
                </>
              
              ) : (
               
                
                <Button 
                  size="small" 
                  onClick={handleNext} 
                >
                  Suivant
                  {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button>)}
            </div>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              Retour
            </Button>
          }
        /></>):(
          <>
          
          </>
        )}
      </div>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose} severity={isSuccess ? 'success' : 'error'}>
          {isSuccess ? 'Activité ajoutée avec succès !' : 'Erreur lors de l\'ajout de l\'activité.'}
        </MuiAlert>
      </Snackbar>
    </Grid>
  );
}

export default AddActivity;